<template>
	<div class="">
		<div id="container2" style="height: 100%;width: 100%;" ref="amap"></div>
	</div>
</template>

<script>
	import AMapLoader from '@amap/amap-jsapi-loader'
	import regionalMaskPath from "./pathArray"
	import regionalMaskPath2 from "./pathArray2"
	import stPeople from "./stPeople"

	export default {
		components: {},
		props: {
			peopleList: {
				type: Array
			}
		},
		data() {
			return {
				map: '',
				infoWindowLayer: '',
				markers: [],
			}
		},
		mounted() {
			let that = this
			setTimeout(function() {
				that.initAMap()
			}, 100);
		},
		beforeDestroy() {
			// 在这里执行需要的清理工作
			this.map.destroy()
		},
		methods: {
			changeLocation() {
				console.log(this.map.getCenter());
					this.map.setCenter([109.37643, 30.963761])
				this.map.setZoom(15)
			},
			initLocation() {
				this.map.setCenter([109.384995, 30.963833])
				this.map.setZoom(15)
			},
			initAMap() {
				const _this = this

				AMapLoader.load({
						key: 'b495ac24ca6062feb0025a227065c103', // 申请好的Web端开发者Key，首次调用 load 时必填
						version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
						plugins: [] // 需要使用的的插件列表，如比例尺'AMap.Scale'等，如果是地图控件，必须再 map.addControl添加
					})
					.then((AMap) => {
						var roadNet = new AMap.TileLayer.RoadNet(); //网路
						var googleSatelliteDomestic = new AMap.TileLayer({
							getTileUrl: "https://map.snkoudai.com/maps/vt?lyrs=s@189&gl=cn&x=[x]&y=[y]&z=[z]",
							zooms: [13, 25]
						});
						const map = new AMap.Map('container2', {
							rotateEnable: true, //是否开启地图旋转交互 鼠标右键 + 鼠标画圈移动 或 键盘Ctrl + 鼠标画圈移动
							pitchEnable: true, // 是否开启地图倾斜交互 鼠标右键 + 鼠标上下移动或键盘Ctrl + 鼠标上下移动
							viewMode: "3D", //是否为3D地图模式  //开启偶尔会报错 Invalid Object: Pixel(NaN, NaN)
							center: [109.384995, 30.963833], //初始化地图中心点位置
							terrain: true, // 开启地形图
							pitch: 10.30000000000021, // 地图俯仰角度，有效范围 0 度- 83 度
							zoom: 15, //初始化地图级别  PC上，参数zoom可设范围：[3,18]； 移动端：参数zoom可设范围：[3,19]
							// rotation: -170.900000000000013,
							layers: [
								// satellite,
								// roadNet,
								googleSatelliteDomestic
								// sanTuoDomestic
							],
							terrain: true
						})
						// 添加控件
						AMap.plugin(
							[
								'AMap.ElasticMarker',
								'AMap.Scale',
								'AMap.ToolBar',
								'AMap.HawkEye',
								'AMap.MapType',
								'AMap.Geolocation',
							],
							() => {
								map.addControl(new AMap.ElasticMarker()) // map.addControl为地图添加对应的控件
								// map.addControl(new AMap.Scale())
								// map.addControl(new AMap.ToolBar())
								// map.addControl(new AMap.HawkEye())
								// map.addControl(new AMap.MapType())
								map.addControl(new AMap.Geolocation())
							}
						)
						_this.map = map

						_this.event = (ev) => {
							// console.log('您移动鼠标到了 [ ' + ev.lnglat.getLng() + ',' + ev.lnglat.getLat() + ' ]')
							// console.log("当前地图Pitch俯仰角度参数为：", this.map.getPitch());
							// console.log("当前地图Zoom初始化地图级别参数为：", this.map.getZoom());
							// console.log("当前地图Rotation初始化顺时针旋转角度参数为：", this.map.getRotation());
							// this.roadPath.push([ev.lnglat.getLng(), ev.lnglat.getLat()])
							// console.log("最新：", this.roadPath);
							// if (this.infoWindow) {
							// 	this.infoWindow.close()
							// }
						}
						_this.map.on('click', _this.event)

						var polygon = new AMap.Polygon({
							path: regionalMaskPath,
							// extrusionHeight:1,//	设置多边形是否拉伸为的立面体高度值，默认值为0
							strokeColor: '#74d5b2',
							strokeWeight: 0,
							strokeOpacity: 1,
							fillColor: '#000000',
							fillOpacity: 0.4,
							// lineJoin: 'round', //折线拐点的绘制样式，默认值为'miter'尖角，其他可选值：'round'圆角、'bevel'斜角
							// lineCap: 'round', //折线两端线帽的绘制样式，默认值为'butt'无头，其他可选值：'round'圆头、'square'方头
							// zIndex: 2 // 设置遮罩线的层级
							// fillColor: ['#FF0000', '#00FF00']  // 从红色渐变到绿色
						});
						_this.map.add(polygon);

						var Polyline = new AMap.Polyline({
							path: regionalMaskPath2[1],
							extrusionHeight: 1, //	设置多边形是否拉伸为的立面体高度值，默认值为0
							strokeColor: '#74d5b2',
							strokeOpacity: 1,
							isOutline: true,
							borderWeight: 1,
							outlineColor: '#74d5b2',
							// lineJoin:'round',
							lineCap: 'round',
							zIndex: 50 // 设置遮罩线的层级
							// strokeWeight: 5,
							// fillColor: '#000000',
						});
						// _this.map.add(Polyline);



						// 创建一个图片覆盖物实例
						const imageLayer = new AMap.ImageLayer({
							url: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/59094b115d8e5136183f0b585434099.png', // 替换为你的图片地址
							bounds: new AMap.Bounds(
								[109.347, 30.984496],
								[109.426204, 30.939063]
							), // 设置图片覆盖物的边界，确保它贴合地图
							zIndex: 99,
						});

						let bounds = imageLayer.getBounds()
						// 将图片覆盖物添加到地图上
						_this.map.add(imageLayer);
						// var marker = new AMap.Marker({
						// 	position: [bounds.northEast.lng + (bounds.northEast.lng - bounds.southWest.lng),
						// 		bounds
						// 		.northEast.lat + (bounds.northEast.lat - bounds.southWest.lat)
						// 	], //位置
						// })
						// _this.map.add(marker);


						for (var i = 0; i < this.peopleList.length; i++) {
							_this.setPeopleMarker(this.peopleList[i])
						}
					})
					.catch((e) => {
						console.log(e)
					})
			},
			setPeopleMarker(info) {
				// 点标记显示内容，HTML要素字符串
				var markerContent = '' +
					`<div class="custom-content-marker">党员：${info.name}</div>`;

				var marker = new AMap.Marker({
					position: this.generateRandomLocation(109.399587, 109.373441, 30.956913, 30.970421),
					// 将 html 传给 content
					content: markerContent,
					// 以 icon 的 [center bottom] 为原点
					offset: new AMap.Pixel(-64, -110)
				});

				this.map.add(marker);
				this.markers.push(marker)

				var html =
					`<div class="infowind flex">
						<img src="${info.image}" alt="" />
						<div class="">
							<div class="info-type">${info.name}</div>
							<div class="info-num">${info.job}</div>
							<div class="info-name">${info.desc}</div>
						</div>
					 </div>`

				//创建信息窗体
				var infoWindow = new AMap.InfoWindow({
					isCustom: true, //使用自定义窗体
					content: html, //信息窗体的内容可以是任意html片段
					offset: new AMap.Pixel(5, -110), //偏移位置
					closeWhenClickMap: false
				});

				//marker移动到上面展示项目概况信息
				marker.on('mouseover', (e) => {
					infoWindow.open(this.map, e.target.getPosition()) //e.target.getPosition()为marker对应坐标
					this.infoWindowLayer = infoWindow
				});

				//移动开关闭
				marker.on('mouseout', (e) => {
					this.infoWindowLayer.close()
				})

			},
			generateRandomLocation(minLng, maxLng, minLat, maxLat) {
				// 确保经度有效
				let lng = minLng + (maxLng - minLng) * Math.random();
				// 确保纬度在有效范围内
				let lat = minLat + (maxLat - minLat) * Math.random();
				// 检查是否超出范围
				if (lng > 180) lng -= 360;
				if (lat > 90) lat = 180 - lat;
				if (lat < -90) lat = -180 - lat;
				return [lng, lat];
			}
		}
	}
</script>

<style>
	.tuli-num {
		font-weight: 400;
		font-size: 12px;
		color: #FFFFFF;
		text-align: right;
	}

	.tuli-title {
		font-weight: 400;
		font-size: 12px;
		color: #FFFFFF;
		margin: 0 40px 0 10px;
	}

	.tuli {
		background: rgba(20, 47, 105, 0.4);
		position: fixed;
		z-index: 999;
		right: 10%;
		bottom: 20%;
		padding: 20px;
	}

	.info-num {
		font-weight: 400;
		font-size: 14px;
		color: #999999;
		margin-top: 5px;
	}

	.info-name {
		font-weight: 400;
		font-size: 15px;
		color: #FFFFFF;
		margin: 14px 0;
		width: 185px;
	}

	.info-type {
		font-weight: 400;
		font-size: 24px;
		color: #FFFFFF;
	}

	.infowind {
		width: 289px;
		height: 110px;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/summary/%E5%AE%B9%E5%99%A8%201.png');
		background-size: 100% 100%;
		padding: 30px;
	}

	.infowind img {
		width: 80px;
		height: 100px;
		margin-right: 10px;
	}

	.custom-content-marker {
		position: relative;
		width: 118px;
		height: 110px;
		/* background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/%E7%BB%84%205%402x.png'); */
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/%E7%BB%84%205%402x%20%281%29.png');
		background-size: 100% 100%;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		text-align: right;
		line-height: 35px;
		padding-right: 10px;
	}

	.custom-content-marker img {
		width: 100%;
		height: 100%;
	}

	.custom-content-marker .close-btn {
		position: absolute;
		top: -6px;
		right: -8px;
		width: 15px;
		height: 15px;
		font-size: 12px;
		background: #ccc;
		border-radius: 50%;
		color: #fff;
		text-align: center;
		line-height: 15px;
		box-shadow: -1px 1px 1px rgba(10, 10, 10, .2);
	}

	.custom-content-marker .close-btn:hover {
		background: #666;
	}
</style>