<template>
	<div class="home">
		<!-- <rabbitIndex style="height: 100%;"></rabbitIndex> -->
		<div class="top" @click="goPage('/',-1)">
			三峡橙庄 水驿三沱
		</div>
		<div class="flex alit top-left" v-if="tabbarIndex > 0">
			<div :class="tabbarIndex == 1 ? 'tabbar-check' : 'tabbar-default'" @click="goPage('/summary',1)">三坨概述
			</div>
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tabbar/%E7%BB%84%2012577%402x.png"
				style="width: 16px;height: 37px;margin: 0 5px;" alt="" />
			<div :class="tabbarIndex == 2 ? 'tabbar-check' : 'tabbar-default'" @click="goPage('/partyBuilding',2)">
				数字党建</div>
		</div>
		<div class="flex alit top-right" style="margin-left: 37%;" v-if="tabbarIndex > 0">
			<div :class="tabbarIndex == 3 ? 'tabbar-check' : 'tabbar-default'" @click="goPage('/tourism',3)">数字农旅
			</div>
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tabbar/%E7%BB%84%2012577%402x.png"
				style="width: 16px;height: 37px;margin: 0 5px;" alt="" />
			<div :class="tabbarIndex == 4 ? 'tabbar-check' : 'tabbar-default'" @click="goPage('/supervise',4)">数字监管
			</div>
		</div>
		<!-- <div class="center"> -->
		<!-- <rabbit-index style="width: 100%;height: 100%;" v-if="menuIndex == 0"></rabbit-index> -->
		<transition :class="transitionName">
			<router-view style="width: 100%;height: 100%;"></router-view>
		</transition>
		<!-- </div> -->
		<!-- <div class="bottom">
			<div class="flex alit justc" style="cursor: pointer;">
				<div class="flexCol alit" @click="changeMenuIndex(0)">
					<img :src="menuIndex == 0 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/rabbit/pc/home/%E5%AE%B9%E5%99%A8%402x%284%29.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/rabbit/pc/home/home-default.png'"
						alt="" class="menu-img" />

					<div :class="menuIndex == 0 ? 'menu-title' :'menu-title-default'">首页</div>
				</div>
				<div class="flexCol alit" style="margin: 0 100px;" @click="changeMenuIndex(1)">
					<img :src="menuIndex == 1 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/rabbit/pc/home/fb-check.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/rabbit/pc/home/%E5%AE%B9%E5%99%A8%402x%285%29.png'"
						alt="" class="menu-img" />
					<div :class="menuIndex == 1 ? 'menu-title' :'menu-title-default'">产业分布</div>
				</div>
				<div class="flexCol alit" @click="changeMenuIndex(2)">
					<img :src="menuIndex == 2 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/rabbit/pc/home/cy-check.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/rabbit/pc/home/%E5%AE%B9%E5%99%A8%402x%286%29.png'"
						alt="" class="menu-img" />
					<div :class="menuIndex == 2 ? 'menu-title' :'menu-title-default'">产业服务</div>
				</div>
				<div class="flexCol alit" style="margin: 0  0 0 100px;" @click="changeMenuIndex(3)">
					<img :src="menuIndex == 3 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/rabbit/pc/home/gx-check.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/rabbit/pc/home/%E5%AE%B9%E5%99%A8%402x%287%29.png'"
						alt="" class="menu-img" />
					<div :class="menuIndex == 3 ? 'menu-title' :'menu-title-default'">供需市场</div>
				</div>
			</div>
		</div> -->
		<!-- <div class="bg"></div> -->
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				menuIndex: -1,
				tabbarIndex: -1,
				transitionName: ""
			}
		},
		created() {
			switch (this.$router.currentRoute.path) {
				case '/summary':
					this.tabbarIndex = 1
					break;
				case '/partyBuilding':
					this.tabbarIndex = 2
					break;
				case '/tourism':
					this.tabbarIndex = 3
					break;
				case '/supervise':
					this.tabbarIndex = 4
					break;
				default:
					break;
			}
		},
		watch: { // 监听，当路由发生变化的时候执行
			$route(to, from) {
				if (to.meta.index > 0 && from.meta.index) {
					if (to.meta.index < from.meta.index) {
						this.transitionName = "slide-right";
					} else {
						this.transitionName = "slide-left";
					}
				} else if (to.meta.index == 0 && from.meta.index > 0) {
					this.transitionName = "";
				}
				switch (this.$router.currentRoute.path) {
					case '/summary':
						this.tabbarIndex = 1
						break;
					case '/partyBuilding':
						this.tabbarIndex = 2
						break;
					case '/tourism':
						this.tabbarIndex = 3
						break;
					case '/supervise':
						this.tabbarIndex = 4
						break;
					default:
						break;
				}

			},
		},
		methods: {
			goPage(e, index) {
				if (this.tabbarIndex == index) return
				this.tabbarIndex = index
				this.$router.push(e)
			},
			changeMenuIndex(e) {
				if (e === this.menuIndex) return;
				this.menuIndex = e
				switch (e) {
					case 0:
						this.$router.push('/')
						break;
					case 1:
						this.$router.push('/industrialDistribution')
						break;
					case 2:
						this.$router.push('/service')
						break;
					case 3:
						this.$router.push('/market')
						break;
					default:
						break;
				}
			}
		}
	}
</script>
<style>
	
	/* 页面切换动画 */
	.slide-right-enter-active,
	.slide-right-leave-active,
	.slide-left-enter-active,
	.slide-left-leave-active {
		/* will-change属性可以提前通知浏览器我们要对元素做什么动画，这样浏览器可以提前准备合适的优化设置 */
		will-change: transform;
		transition: all ease 0.4s;
		-webkit-transition: all ease 0.4s;
		position: absolute;
		width: 100%;
		left: 0;
	}
	
	.slide-right-enter {
		transform: translateX(-100%);
		-webkit-transform: translateX(-100%);
	}
	
	.slide-right-leave-active {
		transform: translateX(100%);
		-webkit-transform: translateX(100%);
	}
	
	.slide-left-enter {
		transform: translateX(100%);
		-webkit-transform: translateX(100%);
	}
	
	.slide-left-leave-active {
		transform: translateX(-100%);
		-webkit-transform: translateX(-100%);
	}
	
	.top-right {
		position: fixed;
		top: 25px;
		right: 15%;
		z-index: 9999;
	}

	.top-left {
		position: fixed;
		top: 25px;
		left: 15%;
		z-index: 9999;
	}

	.top-bar {
		position: fixed;
		top: 20px;
		z-index: 99;
		width: 100%;
	}

	.tabbar-default {
		width: 140px;
		height: 44px;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tabbar/%E7%BB%84%2012637%402x.png');
		background-size: 100% 100%;

		font-weight: 500;
		font-size: 18px;
		color: #FFFFFF;
		line-height: 40px;
		text-align: center;
		cursor: pointer;
	}

	.tabbar-check {
		width: 140px;
		height: 44px;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tabbar/button_2%402x.png');
		background-size: 100% 100%;

		font-family: Source Han Sans, Source Han Sans;
		font-weight: 700;
		font-size: 18px;
		color: #FFFFFF;
		line-height: 40px;
		text-align: center;
		cursor: pointer;
	}

	.center {
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;
	}

	.menu-title {
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 700;
		font-size: 24px;
		color: #F6E18B;
		line-height: 35px;
		text-shadow: 0px 4px 21px rgba(246, 225, 139, 0.6), 0px 0px 7px rgba(246, 225, 139, 0.3);
		text-align: center;
	}

	.menu-title-default {
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 700;
		font-size: 24px;
		color: #B8D3F1;
		line-height: 35px;
		text-shadow: 0px 4px 21px rgba(27, 126, 242, 0.64), 0px 0px 7px #00A3FF;
		text-align: center;
	}

	.menu-img {
		width: 100px;
		height: 97px;
	}

	.bg {
		width: 100%;
		height: 180px;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/%E7%BB%84%2012640%402x.png');
		background-size: 100% 100%;
		position: fixed;
		bottom: 0;
	}

	.bottom {
		position: fixed;
		bottom: 80px;
		left: 32%;
	}

	.top {
		width: 100%;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/Frame%2010279%402x.png');
		height: 80px;
		background-size: 100% 100%;

		font-family: Source Han Sans, Source Han Sans;
		font-weight: 700;
		font-size: 40px;
		color: #FFFFFF;
		line-height: 65px;
		letter-spacing: 10px;
		text-shadow: 0px 4px 21px rgba(27, 126, 242, 0.64), 0px 0px 7px #00A3FF, 0px 4px 10px rgba(0, 0, 0, 0.3);
		text-align: center;
		position: fixed;
		top: 0;
		z-index: 9;
		cursor: pointer;
	}

	.home {
		background-color: aliceblue;
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/rabbit/pc/home/%E7%BB%84%2012587%402x%20%282%29.png');
		background-size: 100% 100%;
	}
</style>