const people = [
    {
        "number": 1,
        "village": "三沱村",
        "type": "先进分子",
        "name": "贺金林",
        "address": "三沱村6组附一号",
        "lng": 106.3333,
        "lat": 29.6666,
        "phone": 15007020459,
        "desc": "在2013年，救落水儿童",
        "phone_1": "人的照片",
        "mz": "汉族",
        "sex": "男",
        "mm": "党员",
        "wh": "大学",
        "people": 3,
        "家庭情况": "家里只有一人，平常由邻居看护"
    },
    {
        "number": 2,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "杨勇",
        "address": "安坪镇三沱村民委员会6组19号",
        "lng": "",
        "lat": "",
        "phone": "13206257336",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "初中",
        "people": "4",
        "家庭情况": "",
        "idcard": "512226197209234050",
        "fp": "否",
        "money": "25245.99",
        "是否现役军人": "否"
    },
    {
        "number": 3,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "杨兴元",
        "address": "安坪镇三沱村民委员会6组27号",
        "lng": "",
        "lat": "",
        "phone": "13896258621",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "2",
        "家庭情况": "",
        "idcard": "512226194812174052",
        "fp": "否",
        "money": "13005.95",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 4,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "黄自孝",
        "address": "安坪镇三沱村民委员会6组82号",
        "lng": "",
        "lat": "",
        "phone": "15178983617",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "文盲或半文盲",
        "people": "5",
        "家庭情况": "",
        "idcard": "512226195009054056",
        "fp": "否",
        "money": "21949.47",
        "是否享受农村居\n民最低生活保障\n(是否低保)": "是",
        "是否现役军人": "否"
    },
    {
        "number": 5,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "叶文海",
        "address": "安坪镇三沱村民委员会6组111号",
        "lng": "",
        "lat": "",
        "phone": "15683557703",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "3",
        "家庭情况": "",
        "idcard": "511225197804114072",
        "fp": "否",
        "money": "13073.37",
        "是否享受农村居\n民最低生活保障\n(是否低保)": "是",
        "是否现役军人": "否"
    },
    {
        "number": 6,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "冉井孟",
        "address": "安坪镇三沱村民委员会3组7号",
        "lng": "",
        "lat": "",
        "phone": 17772405583,
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "6",
        "家庭情况": "",
        "idcard": "512226195205164076",
        "fp": "否",
        "money": "15122.56",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 7,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "余都常",
        "address": "安坪镇三沱村民委员会2组23号",
        "lng": "",
        "lat": "",
        "phone": "13635309650",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "3",
        "家庭情况": "",
        "idcard": "512226197105144050",
        "fp": "否",
        "money": "31936.33",
        "是否现役军人": "否"
    },
    {
        "number": 8,
        "village": "三沱村",
        "type": "监测户",
        "name": "叶方贵",
        "address": "安坪镇三沱村民委员会4组69号",
        "lng": "",
        "lat": "",
        "phone": "15823078529",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "3",
        "家庭情况": "",
        "idcard": "512226195405204052",
        "fp": "否",
        "money": "12290",
        "是否享受农村居\n民最低生活保障\n(是否低保)": "是",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 9,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "黄天富",
        "address": "安坪镇三沱村民委员会1组55号",
        "lng": "",
        "lat": "",
        "phone": "18716792309",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "文盲或半文盲",
        "people": "1",
        "家庭情况": "",
        "idcard": "512226195108124056",
        "fp": "否",
        "money": "20018.77",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 10,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "李财芳",
        "address": "安坪镇三沱村民委员会6组7号附1号",
        "lng": "",
        "lat": "",
        "phone": "15683557727",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "小学",
        "people": "2",
        "家庭情况": "",
        "idcard": "512226195503044064",
        "fp": "否",
        "money": "14040.38",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 11,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "黄天胜",
        "address": "安坪镇三沱村民委员会1组62号",
        "lng": "",
        "lat": "",
        "phone": "13224910917",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "6",
        "家庭情况": "",
        "idcard": "512226196606154113",
        "fp": "否",
        "money": "17768.25",
        "是否现役军人": "否"
    },
    {
        "number": 12,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "黄天堂",
        "address": "安坪镇三沱村民委员会1组71号",
        "lng": "",
        "lat": "",
        "phone": "15816653786",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "4",
        "家庭情况": "",
        "idcard": "512226195908074077",
        "fp": "否",
        "money": "16541.8",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 13,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "黄方华",
        "address": "安坪镇三沱村民委员会1组85号",
        "lng": "",
        "lat": "",
        "phone": "18983517450",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "初中",
        "people": "5",
        "家庭情况": "",
        "idcard": "511225196712244051",
        "fp": "否",
        "money": "27002.51",
        "是否现役军人": "否"
    },
    {
        "number": 14,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "李启国",
        "address": "安坪镇三沱村民委员会1组97号",
        "lng": "",
        "lat": "",
        "phone": "13098623522",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "5",
        "家庭情况": "",
        "idcard": "512226194504124053",
        "fp": "否",
        "money": "13373.12",
        "是否现役军人": "否"
    },
    {
        "number": 15,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "黄胜平",
        "address": "安坪镇三沱村民委员会1组67号",
        "lng": "",
        "lat": "",
        "phone": "13896388225",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "8",
        "家庭情况": "",
        "idcard": "512226197304254076",
        "fp": "否",
        "money": "13427.75",
        "是否享受农村居\n民最低生活保障\n(是否低保)": "是",
        "是否现役军人": "否"
    },
    {
        "number": 16,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "叶忠诚",
        "address": "安坪镇三沱村民委员会1组50号",
        "lng": "",
        "lat": "",
        "phone": "17772491709",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "4",
        "家庭情况": "",
        "idcard": "511225196304154057",
        "fp": "否",
        "money": "27465.17",
        "是否现役军人": "否"
    },
    {
        "number": 17,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "黄建平",
        "address": "安坪镇三沱村民委员会1组92号",
        "lng": "",
        "lat": "",
        "phone": "15923489438",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "初中",
        "people": "5",
        "家庭情况": "",
        "idcard": "511225197111164050",
        "fp": "否",
        "money": "11176.29",
        "是否现役军人": "否"
    },
    {
        "number": 18,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "向康均",
        "address": "安坪镇三沱村民委员会3组50号",
        "lng": "",
        "lat": "",
        "phone": "15683557587",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "初中",
        "people": "4",
        "家庭情况": "",
        "idcard": "512226197501144052",
        "fp": "否",
        "money": "24653.11",
        "是否现役军人": "否"
    },
    {
        "number": 19,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "李胜梅",
        "address": "安坪镇三沱村民委员会3组184号",
        "lng": "",
        "lat": "",
        "phone": "18723761965",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "初中",
        "people": "2",
        "家庭情况": "",
        "idcard": "512226197204244065",
        "fp": "否",
        "money": "30159.1",
        "是否现役军人": "否"
    },
    {
        "number": 20,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "刘永成",
        "address": "安坪镇三沱村民委员会3组136号",
        "lng": "",
        "lat": "",
        "phone": "13251112108",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "3",
        "家庭情况": "",
        "idcard": "511225195405044055",
        "fp": "否",
        "money": "21793.79",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 21,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "冉述安",
        "address": "安坪镇三沱村民委员会3组147号",
        "lng": "",
        "lat": "",
        "phone": "15123501557",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "初中",
        "people": "4",
        "家庭情况": "",
        "idcard": "511225196705034056",
        "fp": "否",
        "money": "22160.81",
        "是否现役军人": "否"
    },
    {
        "number": 22,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "周在柏",
        "address": "安坪镇三沱村民委员会3组133号",
        "lng": "",
        "lat": "",
        "phone": "15826423996",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "2",
        "家庭情况": "",
        "idcard": "511225194807024052",
        "fp": "否",
        "money": "11971.61",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 23,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "冉景东",
        "address": "安坪镇三沱村民委员会6组23号",
        "lng": "",
        "lat": "",
        "phone": "19115309765",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "初中",
        "people": "4",
        "家庭情况": "",
        "idcard": "512226196311254053",
        "fp": "否",
        "money": "13744.96",
        "是否现役军人": "否"
    },
    {
        "number": 24,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "刘登全",
        "address": "安坪镇三沱村民委员会5组159号",
        "lng": "",
        "lat": "",
        "phone": 13106713282,
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "初中",
        "people": "4",
        "家庭情况": "",
        "idcard": "50023619831105405X",
        "fp": "否",
        "money": "24362.24",
        "是否现役军人": "否"
    },
    {
        "number": 25,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "刘良德",
        "address": "安坪镇三沱村民委员会5组117号",
        "lng": "",
        "lat": "",
        "phone": "13320317245",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "7",
        "家庭情况": "",
        "idcard": "512226196606184072",
        "fp": "否",
        "money": "15460.05",
        "是否现役军人": "否"
    },
    {
        "number": 26,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "刘良成",
        "address": "安坪镇三沱村民委员会5组118号",
        "lng": "",
        "lat": "",
        "phone": "13452703247",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "初中",
        "people": "3",
        "家庭情况": "",
        "idcard": "512226196211114053",
        "fp": "否",
        "money": "41296.78",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 27,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "叶文礼",
        "address": "安坪镇三沱村民委员会5组1号",
        "lng": "",
        "lat": "",
        "phone": "13114003145",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "文盲或半文盲",
        "people": "3",
        "家庭情况": "",
        "idcard": "512226197108224056",
        "fp": "否",
        "money": "25216.89",
        "是否现役军人": "否"
    },
    {
        "number": 28,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "黄海全",
        "address": "安坪镇三沱村民委员会1组87号",
        "lng": "",
        "lat": "",
        "phone": "13320318742",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "5",
        "家庭情况": "",
        "idcard": "512226197411174055",
        "fp": "否",
        "money": "25515.04",
        "是否现役军人": "否"
    },
    {
        "number": 29,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "黄天云",
        "address": "安坪镇三沱村民委员会1组63号",
        "lng": "",
        "lat": "",
        "phone": "18166377916",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "7",
        "家庭情况": "",
        "idcard": "512226194608124090",
        "fp": "否",
        "money": "19173.87",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 30,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "田颜礼",
        "address": "安坪镇三沱村民委员会1组60号",
        "lng": "",
        "lat": "",
        "phone": "15202330031",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "小学",
        "people": "2",
        "家庭情况": "",
        "idcard": "512226195604044063",
        "fp": "否",
        "money": "21168.86",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 31,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "黄天亮",
        "address": "安坪镇三沱村民委员会1组39号",
        "lng": "",
        "lat": "",
        "phone": "13896928416",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "7",
        "家庭情况": "",
        "idcard": "512226195603044053",
        "fp": "否",
        "money": "17979.68",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 32,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "冉启学",
        "address": "安坪镇三沱村民委员会6组109号",
        "lng": "",
        "lat": "",
        "phone": "17323678539",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "4",
        "家庭情况": "",
        "idcard": "500236198402264052",
        "fp": "否",
        "money": "14331.77",
        "是否现役军人": "否"
    },
    {
        "number": 33,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "张贤兵",
        "address": "安坪镇三沱村民委员会3组124号",
        "lng": "",
        "lat": "",
        "phone": "18581451623",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "4",
        "家庭情况": "",
        "idcard": "512226197112034052",
        "fp": "否",
        "money": "19323.65",
        "是否现役军人": "否"
    },
    {
        "number": 34,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "张国臣",
        "address": "安坪镇三沱村民委员会1组44号",
        "lng": "",
        "lat": "",
        "phone": "13983519572",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "6",
        "家庭情况": "",
        "idcard": "512226195106184055",
        "fp": "否",
        "money": "17921.09",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 35,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "成书定",
        "address": "安坪镇三沱村民委员会6组41号",
        "lng": "",
        "lat": "",
        "phone": 15023441085,
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "文盲或半文盲",
        "people": "2",
        "家庭情况": "",
        "idcard": "511225196801044051",
        "fp": "否",
        "money": "23152.98",
        "是否享受农村居\n民最低生活保障\n(是否低保)": "是",
        "是否现役军人": "否"
    },
    {
        "number": 36,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "毕方兴",
        "address": "安坪镇三沱村民委员会5组138号",
        "lng": "",
        "lat": "",
        "phone": " 15922819775",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "文盲或半文盲",
        "people": "1",
        "家庭情况": "",
        "idcard": "512226195207094091",
        "fp": "否",
        "money": "22573.93",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 37,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "余定中",
        "address": "安坪镇三沱村民委员会5组130号",
        "lng": "",
        "lat": "",
        "phone": "13628418616",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "4",
        "家庭情况": "",
        "idcard": "51222619641123405X",
        "fp": "否",
        "money": "19217.84",
        "是否现役军人": "否"
    },
    {
        "number": 38,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "余定胜",
        "address": "安坪镇三沱村民委员会5组21号",
        "lng": "",
        "lat": "",
        "phone": 15123455178,
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "2",
        "家庭情况": "",
        "idcard": "512226196809184056",
        "fp": "否",
        "money": "30997.92",
        "是否现役军人": "否"
    },
    {
        "number": 39,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "毛西亮",
        "address": "安坪镇三沱村民委员会5组67号",
        "lng": "",
        "lat": "",
        "phone": "15123508179",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "2",
        "家庭情况": "",
        "idcard": "512226195512284051",
        "fp": "否",
        "money": "15975.44",
        "是否享受农村居\n民最低生活保障\n(是否低保)": "是",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 40,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "熊宣章",
        "address": "安坪镇三沱村民委员会4组85号",
        "lng": "",
        "lat": "",
        "phone": "15826435794",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "文盲或半文盲",
        "people": "1",
        "家庭情况": "",
        "idcard": "511225197411304051",
        "fp": "否",
        "money": "39948.84",
        "是否享受农村居\n民最低生活保障\n(是否低保)": "是",
        "是否现役军人": "否"
    },
    {
        "number": 41,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "王益英",
        "address": "安坪镇三沱村民委员会2组80号",
        "lng": "",
        "lat": "",
        "phone": "13896202430",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "小学",
        "people": "3",
        "家庭情况": "",
        "idcard": "511225197105064061",
        "fp": "否",
        "money": "25773.22",
        "是否现役军人": "否"
    },
    {
        "number": 42,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "刘从军",
        "address": "安坪镇三沱村民委员会4组127号",
        "lng": "",
        "lat": "",
        "phone": "15223513977",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "4",
        "家庭情况": "",
        "idcard": "512226197310154055",
        "fp": "否",
        "money": "25308.92",
        "是否现役军人": "否"
    },
    {
        "number": 43,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "刘从海",
        "address": "安坪镇三沱村民委员会4组128号",
        "lng": "",
        "lat": "",
        "phone": "13668440343",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "初中",
        "people": "4",
        "家庭情况": "",
        "idcard": "512226197707074053",
        "fp": "否",
        "money": "48528.92",
        "是否现役军人": "否"
    },
    {
        "number": 44,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "操和光",
        "address": "安坪镇三沱村民委员会2组18号",
        "lng": "",
        "lat": "",
        "phone": "17783238168",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "初中",
        "people": "2",
        "家庭情况": "",
        "idcard": "512226197207234057",
        "fp": "否",
        "money": "31087.25",
        "是否现役军人": "否"
    },
    {
        "number": 45,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "李如其",
        "address": "安坪镇三沱村民委员会2组47号",
        "lng": "",
        "lat": "",
        "phone": 17323706363,
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "文盲或半文盲",
        "people": "3",
        "家庭情况": "",
        "idcard": "51222619520209405X",
        "fp": "否",
        "money": "20417",
        "是否现役军人": "否"
    },
    {
        "number": 46,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "樊先友",
        "address": "安坪镇三沱村民委员会6组1号",
        "lng": "",
        "lat": "",
        "phone": "13320316743",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "文盲或半文盲",
        "people": "2",
        "家庭情况": "",
        "idcard": "512226194510074064",
        "fp": "否",
        "money": "17724.4",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 47,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "冉景河",
        "address": "安坪镇三沱村民委员会6组4号",
        "lng": "",
        "lat": "",
        "phone": "13320316743",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "3",
        "家庭情况": "",
        "idcard": "511225197708154074",
        "fp": "否",
        "money": "22392.25",
        "是否现役军人": "否"
    },
    {
        "number": 48,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "樊建平",
        "address": "安坪镇三沱村民委员会4组25号",
        "lng": "",
        "lat": "",
        "phone": "15025539719",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "1",
        "家庭情况": "",
        "idcard": "512226196511054056",
        "fp": "否",
        "money": "48669.37",
        "是否现役军人": "否"
    },
    {
        "number": 49,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "冉隆菊",
        "address": "安坪镇三沱村民委员会1组80号",
        "lng": "",
        "lat": "",
        "phone": "15223619514",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "小学",
        "people": "5",
        "家庭情况": "",
        "idcard": "512226196306064060",
        "fp": "否",
        "money": "24955.44",
        "是否现役军人": "否"
    },
    {
        "number": 50,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "樊德兵",
        "address": "安坪镇三沱村民委员会3组158号",
        "lng": "",
        "lat": "",
        "phone": "18323640831",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "4",
        "家庭情况": "",
        "idcard": "511225197302054057",
        "fp": "否",
        "money": "26368.12",
        "是否现役军人": "否"
    },
    {
        "number": 51,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "黄朝琼",
        "address": "安坪镇三沱村民委员会4组144号",
        "lng": "",
        "lat": "",
        "phone": "18523780555",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "小学",
        "people": "2",
        "家庭情况": "",
        "idcard": "511225196207204067",
        "fp": "否",
        "money": "27347.31",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 52,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "王燕",
        "address": "安坪镇三沱村民委员会4组32号",
        "lng": "",
        "lat": "",
        "phone": "15696456333",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "小学",
        "people": "4",
        "家庭情况": "",
        "idcard": "512226197503174060",
        "fp": "否",
        "money": "21156.21",
        "是否现役军人": "否"
    },
    {
        "number": 53,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "毕方高",
        "address": "安坪镇三沱村民委员会4组93号",
        "lng": "",
        "lat": "",
        "phone": "13330304798",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "3",
        "家庭情况": "",
        "idcard": "511225195908244059",
        "fp": "否",
        "money": "19673.07",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 54,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "杨中林",
        "address": "安坪镇三沱村民委员会4组106号",
        "lng": "",
        "lat": "",
        "phone": "18716548735",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "2",
        "家庭情况": "",
        "idcard": "512226196807194074",
        "fp": "否",
        "money": "40035.94",
        "是否现役军人": "否"
    },
    {
        "number": 55,
        "village": "三沱村",
        "type": "监测户",
        "name": "李启菊",
        "address": "安坪镇三沱村民委员会6组91号",
        "lng": "",
        "lat": "",
        "phone": "15523718845",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "小学",
        "people": "4",
        "家庭情况": "",
        "idcard": "511225197110024064",
        "fp": "否",
        "money": "16806.74",
        "是否现役军人": "否"
    },
    {
        "number": 56,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "毕梦麟",
        "address": "安坪镇三沱村民委员会6组10号",
        "lng": "",
        "lat": "",
        "phone": "17783892848",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "初中",
        "people": "7",
        "家庭情况": "",
        "idcard": "512226196611174119",
        "fp": "否",
        "money": "18859.91",
        "是否现役军人": "否"
    },
    {
        "number": 57,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "周禄成",
        "address": "安坪镇三沱村民委员会6组25号",
        "lng": "",
        "lat": "",
        "phone": "13212511758",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "文盲或半文盲",
        "people": "2",
        "家庭情况": "",
        "idcard": "512226196202284069",
        "fp": "否",
        "money": "40412.65",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 58,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "朱盛兴",
        "address": "安坪镇三沱村民委员会1组88号",
        "lng": "",
        "lat": "",
        "phone": "17382206384",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "3",
        "家庭情况": "",
        "idcard": "51222619550110641X",
        "fp": "否",
        "money": "26467.58",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 59,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "黄玉忠",
        "address": "安坪镇三沱村民委员会1组84号",
        "lng": "",
        "lat": "",
        "phone": "15178962739",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "3",
        "家庭情况": "",
        "idcard": "511225197805084055",
        "fp": "否",
        "money": "17195.42",
        "是否现役军人": "否"
    },
    {
        "number": 60,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "张登树",
        "address": "安坪镇三沱村民委员会1组15号",
        "lng": "",
        "lat": "",
        "phone": "15923457818",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "4",
        "家庭情况": "",
        "idcard": "511225196401074059",
        "fp": "否",
        "money": "19358.33",
        "是否现役军人": "否"
    },
    {
        "number": 61,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "张登林",
        "address": "安坪镇三沱村民委员会1组14号",
        "lng": "",
        "lat": "",
        "phone": "15223193193",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "初中",
        "people": "5",
        "家庭情况": "",
        "idcard": "512226196104034058",
        "fp": "否",
        "money": "15627.06",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 62,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "杨沛林",
        "address": "安坪镇三沱村民委员会1组10号",
        "lng": "",
        "lat": "",
        "phone": "15696523216",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "文盲或半文盲",
        "people": "4",
        "家庭情况": "",
        "idcard": "511225196812134079",
        "fp": "否",
        "money": "39558.92",
        "是否现役军人": "否"
    },
    {
        "number": 63,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "张本华",
        "address": "安坪镇三沱村民委员会5组94号",
        "lng": "",
        "lat": "",
        "phone": "18290540624",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "初中",
        "people": "3",
        "家庭情况": "",
        "idcard": "511225197006194055",
        "fp": "否",
        "money": "26647.67",
        "是否现役军人": "否"
    },
    {
        "number": 64,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "黄小君",
        "address": "安坪镇三沱村民委员会4组74号",
        "lng": "",
        "lat": "",
        "phone": 15202378533,
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "小学",
        "people": "2",
        "家庭情况": "",
        "idcard": "512226197004094066",
        "fp": "否",
        "money": "42458.92",
        "是否现役军人": "否"
    },
    {
        "number": 65,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "樊德祥",
        "address": "安坪镇三沱村民委员会4组8号",
        "lng": "",
        "lat": "",
        "phone": "16623363183",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "4",
        "家庭情况": "",
        "idcard": "512226196605094059",
        "fp": "否",
        "money": "18298.84",
        "是否现役军人": "否"
    },
    {
        "number": 66,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "罗仲福",
        "address": "安坪镇三沱村民委员会6组80号",
        "lng": "",
        "lat": "",
        "phone": "15823798723",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "2",
        "家庭情况": "",
        "idcard": "512226194612124050",
        "fp": "否",
        "money": "26430.63",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 67,
        "village": "三沱村",
        "type": "脱贫户",
        "name": "罗仲义",
        "address": "安坪镇三沱村民委员会6组78号",
        "lng": "",
        "lat": "",
        "phone": "18723697083",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "小学",
        "people": "4",
        "家庭情况": "",
        "idcard": "512226194212104077",
        "fp": "否",
        "money": "23195.5",
        "是否参加城乡居\n民基本养老保险": "是",
        "是否现役军人": "否"
    },
    {
        "number": 68,
        "village": "三沱村",
        "type": "监测户",
        "name": "黄太祥",
        "address": "安坪镇三沱村民委员会4组4号",
        "lng": "",
        "lat": "",
        "phone": "13896206353",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "初中",
        "people": "5",
        "家庭情况": "",
        "idcard": "512226197506144051",
        "fp": "否",
        "money": 14590.71,
        "是否现役军人": "否"
    },
    {
        "number": 69,
        "village": "三沱村",
        "type": "监测户",
        "name": "罗克菊",
        "address": "安坪镇三沱村民委员会6组83号",
        "lng": "",
        "lat": "",
        "phone": "18716502168",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "初中",
        "people": "3",
        "家庭情况": "",
        "idcard": "511225197903104064",
        "fp": "否",
        "money": 14396.16,
        "是否现役军人": "否"
    },
    {
        "number": 70,
        "village": "三沱村",
        "type": "低保户",
        "name": "叶文海",
        "address": "安坪镇三沱村民委员会6组111号",
        "lng": "",
        "lat": "",
        "phone": "15683557703",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225197804114072",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 71,
        "village": "三沱村",
        "type": "低保户",
        "name": "黄天贵",
        "address": "安坪镇三沱村民委员会6组7号附1号",
        "lng": "",
        "lat": "",
        "phone": "15683557727",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226195210054058",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 72,
        "village": "三沱村",
        "type": "低保户",
        "name": "毛西亮",
        "address": "安坪镇三沱村民委员会5组67号",
        "lng": "",
        "lat": "",
        "phone": "15123508179",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226195512284051",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 73,
        "village": "三沱村",
        "type": "低保户",
        "name": "翁术蓉",
        "address": "安坪镇三沱村民委员会2组180号",
        "lng": "",
        "lat": "",
        "phone": "15730677481",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225197408184087",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 74,
        "village": "三沱村",
        "type": "低保户",
        "name": "张本红",
        "address": "安坪镇三沱村民委员会5组154号",
        "lng": "",
        "lat": "",
        "phone": "13320315376",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226197408214060",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 75,
        "village": "三沱村",
        "type": "低保户",
        "name": "黄自孝",
        "address": "安坪镇三沱村民委员会6组82号",
        "lng": "",
        "lat": "",
        "phone": "15736330885",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226195009054056",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 76,
        "village": "三沱村",
        "type": "低保户",
        "name": "熊宣章",
        "address": "安坪镇三沱村民委员会4组85号",
        "lng": "",
        "lat": "",
        "phone": "15826435794",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225197411304051",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 77,
        "village": "三沱村",
        "type": "低保户",
        "name": "余进伟",
        "address": "安坪镇三沱村民委员会1组30号",
        "lng": "",
        "lat": "",
        "phone": "18696812907",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236198810044075",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 78,
        "village": "三沱村",
        "type": "低保户",
        "name": "冉述富",
        "address": "安坪镇三沱村民委员会3组146号",
        "lng": "",
        "lat": "",
        "phone": "15157462593",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "51222619510913407X",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 79,
        "village": "三沱村",
        "type": "低保户",
        "name": "陈辉香",
        "address": "安坪镇三沱村民委员会4组156号",
        "lng": "",
        "lat": "",
        "phone": "18723645851",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226196601204062",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 80,
        "village": "三沱村",
        "type": "低保户",
        "name": "黄胜平",
        "address": "安坪镇三沱村民委员会1组67号",
        "lng": "",
        "lat": "",
        "phone": "18323613489",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226197304254076",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 81,
        "village": "三沱村",
        "type": "低保户",
        "name": "黄金海",
        "address": "安坪镇三沱村民委员会1组39号",
        "lng": "",
        "lat": "",
        "phone": "13896928416",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236198412184099",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 82,
        "village": "三沱村",
        "type": "低保户",
        "name": "黄鑫",
        "address": "安坪镇三沱村民委员会1组92号",
        "lng": "",
        "lat": "",
        "phone": "15923489438",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236199710024055",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 83,
        "village": "三沱村",
        "type": "低保户",
        "name": "叶方贵",
        "address": "安坪镇三沱村民委员会4组69号",
        "lng": "",
        "lat": "",
        "phone": "18716566487",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226195405204052",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 84,
        "village": "三沱村",
        "type": "低保户",
        "name": "周素英",
        "address": "安坪镇三沱村民委员会1组83号",
        "lng": "",
        "lat": "",
        "phone": "13098642953",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225195902204064",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 85,
        "village": "三沱村",
        "type": "低保户",
        "name": "成书定",
        "address": "安坪镇三沱村民委员会6组41号",
        "lng": "",
        "lat": "",
        "phone": "13594723157",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225196801044051",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 86,
        "village": "三沱村",
        "type": "特困户",
        "name": "毕道平",
        "address": "安坪镇三沱村民委员会6组9号",
        "lng": "",
        "lat": "",
        "phone": 18423363715,
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226195107194095",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 87,
        "village": "三沱村",
        "type": "特困户",
        "name": "张贤祝",
        "address": "安坪镇三沱村民委员会6组103号",
        "lng": "",
        "lat": "",
        "phone": 15723596233,
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225195112224052",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 88,
        "village": "三沱村",
        "type": "特困户",
        "name": "毕明喜",
        "address": "安坪镇三沱村民委员会4组89号",
        "lng": "",
        "lat": "",
        "phone": 13628418511,
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225194207054055",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 90,
        "village": "三沱村",
        "type": "特困户",
        "name": "叶文礼",
        "address": "安坪镇三沱村民委员会5组1号",
        "lng": "",
        "lat": "",
        "phone": 13114003145,
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226197108224056",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 91,
        "village": "三沱村",
        "type": "特困户",
        "name": "杜大平",
        "address": "安坪镇三沱村民委员会6组87号",
        "lng": "",
        "lat": "",
        "phone": 13594475178,
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225198303034052",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 92,
        "village": "三沱村",
        "type": "残疾人",
        "name": "刘从海",
        "address": "安坪镇三沱村4组128号",
        "lng": "",
        "lat": "",
        "phone": "13668440343",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226197707074053",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 93,
        "village": "三沱村",
        "type": "残疾人",
        "name": "张玉梅",
        "address": "安坪镇三沱村2组78号",
        "lng": "",
        "lat": "",
        "phone": "",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236199005124069",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 94,
        "village": "三沱村",
        "type": "残疾人",
        "name": "熊师云",
        "address": "安坪镇三沱村3组40号",
        "lng": "",
        "lat": "",
        "phone": "13452667250",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226197310214062",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 95,
        "village": "三沱村",
        "type": "残疾人",
        "name": "毕道芝",
        "address": "安坪乡三沱村5组67号",
        "lng": "",
        "lat": "",
        "phone": "13594418409",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226196607034068",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 96,
        "village": "三沱村",
        "type": "残疾人",
        "name": "熊宣章",
        "address": "安坪镇三沱村4组85号",
        "lng": "",
        "lat": "",
        "phone": "15826435794",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225197411304051",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 97,
        "village": "三沱村",
        "type": "残疾人",
        "name": "黄小利",
        "address": "安坪镇三沱村4组26号",
        "lng": "",
        "lat": "",
        "phone": "18184099841",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "51222619730715410X",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 98,
        "village": "三沱村",
        "type": "残疾人",
        "name": "黄自孝",
        "address": "安坪镇三沱村6组82号",
        "lng": "",
        "lat": "",
        "phone": "15736330885",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226195009054056",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 99,
        "village": "三沱村",
        "type": "残疾人",
        "name": "成金平",
        "address": "安坪镇三沱村3组84号",
        "lng": "",
        "lat": "",
        "phone": "15223722705",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226197709114055",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 100,
        "village": "三沱村",
        "type": "残疾人",
        "name": "操燎原",
        "address": "安坪乡三沱村2组20号",
        "lng": "",
        "lat": "",
        "phone": "15025582356",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225198302114077",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 101,
        "village": "三沱村",
        "type": "残疾人",
        "name": "黄长容",
        "address": "安坪镇三沱村1组67号",
        "lng": "",
        "lat": "",
        "phone": "13896388225",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236200103064106",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 102,
        "village": "三沱村",
        "type": "残疾人",
        "name": "冉京城",
        "address": "安坪镇三沱村3组147号",
        "lng": "",
        "lat": "",
        "phone": "15123501557",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236199709154071",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 103,
        "village": "三沱村",
        "type": "残疾人",
        "name": "叶文海",
        "address": "安坪镇三沱村6组",
        "lng": "",
        "lat": "",
        "phone": "15683557703",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225197804114072",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 104,
        "village": "三沱村",
        "type": "残疾人",
        "name": "刘国清",
        "address": "安坪镇三沱村6组55号",
        "lng": "",
        "lat": "",
        "phone": "19115160347",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225195207264065",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 105,
        "village": "三沱村",
        "type": "残疾人",
        "name": "黄业书",
        "address": "安坪镇三沱村6组55号",
        "lng": "",
        "lat": "",
        "phone": "19115160347",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225194104224058",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 106,
        "village": "三沱村",
        "type": "残疾人",
        "name": "毕梦麟",
        "address": "安坪镇三沱村6组10号",
        "lng": "",
        "lat": "",
        "phone": "15696517497",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226196611174119",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 107,
        "village": "三沱村",
        "type": "残疾人",
        "name": "余进美",
        "address": "安坪镇三沱村1组28号",
        "lng": "",
        "lat": "",
        "phone": "18883872705",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236199810164063",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 108,
        "village": "三沱村",
        "type": "残疾人",
        "name": "毕道木",
        "address": "安坪镇三沱村5组84号",
        "lng": "",
        "lat": "",
        "phone": "13206241796",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226194907044058",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 109,
        "village": "三沱村",
        "type": "残疾人",
        "name": "樊德兵",
        "address": "安坪镇三沱村3组158号",
        "lng": "",
        "lat": "",
        "phone": "18323640831",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225197302054057",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 110,
        "village": "三沱村",
        "type": "残疾人",
        "name": "黄俊元",
        "address": "安坪镇三沱村4组45号",
        "lng": "",
        "lat": "",
        "phone": "15730622128",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "中共党员",
        "wh": "初中",
        "people": "",
        "家庭情况": "",
        "idcard": "512226197007214051",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 111,
        "village": "三沱村",
        "type": "残疾人",
        "name": "叶文礼",
        "address": "安坪镇三沱村5组1号",
        "lng": "",
        "lat": "",
        "phone": "13212545127",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226197108224056",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 112,
        "village": "三沱村",
        "type": "残疾人",
        "name": "成金玉",
        "address": "安坪镇三沱村3组",
        "lng": "",
        "lat": "",
        "phone": "15826381808",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225197811104067",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 113,
        "village": "三沱村",
        "type": "残疾人",
        "name": "成书定",
        "address": "安坪镇三沱村6组41号",
        "lng": "",
        "lat": "",
        "phone": "15023441085",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225196801044051",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 114,
        "village": "三沱村",
        "type": "残疾人",
        "name": "冉凤兰",
        "address": "安坪镇三沱村3组153号",
        "lng": "",
        "lat": "",
        "phone": "13212570688",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "50023619911213406X",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 115,
        "village": "三沱村",
        "type": "残疾人",
        "name": "廖丹",
        "address": "安坪镇三沱村2组",
        "lng": "",
        "lat": "",
        "phone": "",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "50023619830923406X",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 116,
        "village": "三沱村",
        "type": "残疾人",
        "name": "李如其",
        "address": "安坪镇三沱村2组47号",
        "lng": "",
        "lat": "",
        "phone": "18223883167",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "51222619520209405X",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 117,
        "village": "三沱村",
        "type": "残疾人",
        "name": "樊赵祺",
        "address": "安坪镇三沱村4组",
        "lng": "",
        "lat": "",
        "phone": "18323647364",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236201202264052",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 118,
        "village": "三沱村",
        "type": "残疾人",
        "name": "杨森智",
        "address": "安坪镇三沱村6组",
        "lng": "",
        "lat": "",
        "phone": "13193219599",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236200608074051",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 119,
        "village": "三沱村",
        "type": "残疾人",
        "name": "汪浩",
        "address": "安坪镇三沱村4组",
        "lng": "",
        "lat": "",
        "phone": "13132331001",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236199911014056",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 120,
        "village": "三沱村",
        "type": "残疾人",
        "name": "杨美",
        "address": "安坪镇三沱村3组40号",
        "lng": "",
        "lat": "",
        "phone": "13452667250",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236200601154104",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 121,
        "village": "三沱村",
        "type": "残疾人",
        "name": "张勇",
        "address": "安坪镇三沱村3组124号",
        "lng": "",
        "lat": "",
        "phone": "18083059482",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236199206074053",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 122,
        "village": "三沱村",
        "type": "残疾人",
        "name": "毕锐",
        "address": "安坪镇三沱村4组72号",
        "lng": "",
        "lat": "",
        "phone": "18623226819",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226197501014055",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 123,
        "village": "三沱村",
        "type": "残疾人",
        "name": "张荣",
        "address": "安坪镇三沱村1组44号",
        "lng": "",
        "lat": "",
        "phone": "13916944281",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225198102154058",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 124,
        "village": "三沱村",
        "type": "残疾人",
        "name": "成金华",
        "address": "安坪镇三沱村3组91号",
        "lng": "",
        "lat": "",
        "phone": "",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226197205194055",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 125,
        "village": "三沱村",
        "type": "残疾人",
        "name": "沈学平",
        "address": "安坪镇三沱村2组55号",
        "lng": "",
        "lat": "",
        "phone": "",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225197502124072",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 126,
        "village": "三沱村",
        "type": "残疾人",
        "name": "冉启学",
        "address": "安坪镇三沱村6组109号",
        "lng": "",
        "lat": "",
        "phone": "",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236198402264052",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 127,
        "village": "三沱村",
        "type": "残疾人",
        "name": "马功华",
        "address": "永安街道竹枝路162号附1号2幢2单元5-2",
        "lng": "",
        "lat": "",
        "phone": "18223734088",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226194907134053",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 128,
        "village": "三沱村",
        "type": "残疾人",
        "name": "潘帮英",
        "address": "安坪镇三沱村5组94号",
        "lng": "",
        "lat": "",
        "phone": "",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226197009193186",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 129,
        "village": "三沱村",
        "type": "残疾人",
        "name": "黄天贵",
        "address": "安坪镇三沱村6组7号附1号",
        "lng": "",
        "lat": "",
        "phone": "15683557727",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226195210054058",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 130,
        "village": "三沱村",
        "type": "残疾人",
        "name": "杜大平",
        "address": "安坪镇三沱村6组87号",
        "lng": "",
        "lat": "",
        "phone": "",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225198303034052",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 131,
        "village": "三沱村",
        "type": "残疾人",
        "name": "李发银",
        "address": "安坪镇三沱村3组120号",
        "lng": "",
        "lat": "",
        "phone": "15870446625",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225198206224057",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 132,
        "village": "三沱村",
        "type": "残疾人",
        "name": "黄方平",
        "address": "安坪镇三沱村1组56号",
        "lng": "",
        "lat": "",
        "phone": "13012310920",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226197303024092",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 133,
        "village": "三沱村",
        "type": "残疾人",
        "name": "王忠彪",
        "address": "安坪镇三沱村2组39号",
        "lng": "",
        "lat": "",
        "phone": "18581357133",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "50023619930525405X",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 134,
        "village": "三沱村",
        "type": "残疾人",
        "name": "余世香",
        "address": "安坪镇三坨村1组67号",
        "lng": "",
        "lat": "",
        "phone": "13896388225",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225197404284064",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 135,
        "village": "三沱村",
        "type": "残疾人",
        "name": "余进伟",
        "address": "安坪镇三沱村1组30号",
        "lng": "",
        "lat": "",
        "phone": "15523632908",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236198810044075",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 136,
        "village": "三沱村",
        "type": "残疾人",
        "name": "刘兴学",
        "address": "安坪镇三沱村6组38号",
        "lng": "",
        "lat": "",
        "phone": "18367203288",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225197206184054",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 137,
        "village": "三沱村",
        "type": "残疾人",
        "name": "李本秀",
        "address": "安坪镇三沱村2组129号",
        "lng": "",
        "lat": "",
        "phone": "13224917470",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226194007064061",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 138,
        "village": "三沱村",
        "type": "残疾人",
        "name": "翁术蓉",
        "address": "安坪镇三沱村2组180号",
        "lng": "",
        "lat": "",
        "phone": "15730677481",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "511225197408184087",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 139,
        "village": "三沱村",
        "type": "残疾人",
        "name": "黄小君",
        "address": "安坪镇三坨村4组74号",
        "lng": "",
        "lat": "",
        "phone": "15202378533",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226197004094066",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 140,
        "village": "三沱村",
        "type": "残疾人",
        "name": "熊宣付",
        "address": "安坪镇三沱村4组129号",
        "lng": "",
        "lat": "",
        "phone": "13714524599",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226196404184058",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 141,
        "village": "三沱村",
        "type": "残疾人",
        "name": "毕昱恒",
        "address": "安坪镇三沱村6组13号",
        "lng": "",
        "lat": "",
        "phone": "17318249760",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236201505174070",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 142,
        "village": "三沱村",
        "type": "残疾人",
        "name": "刘琴",
        "address": "安坪镇三沱村6组38号",
        "lng": "",
        "lat": "",
        "phone": "18367203288",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236199604284064",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 143,
        "village": "三沱村",
        "type": "残疾人",
        "name": "黄鑫",
        "address": "安坪镇三沱村1组92号",
        "lng": "",
        "lat": "",
        "phone": "15310051078",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236199710024055",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 144,
        "village": "三沱村",
        "type": "残疾人",
        "name": "陈林",
        "address": "安坪镇三沱村4组76号",
        "lng": "",
        "lat": "",
        "phone": "13996539150",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226195704204052",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 145,
        "village": "三沱村",
        "type": "残疾人",
        "name": "余素容",
        "address": "安坪镇三沱村1组",
        "lng": "",
        "lat": "",
        "phone": "17382206384",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226196001274606",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 146,
        "village": "三沱村",
        "type": "残疾人",
        "name": "张伟",
        "address": "安坪镇三沱村3组124号",
        "lng": "",
        "lat": "",
        "phone": "17602356230",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236199812274071",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 147,
        "village": "三沱村",
        "type": "残疾人",
        "name": "黄俊金",
        "address": "安坪镇三坨村4组65号",
        "lng": "",
        "lat": "",
        "phone": "13436286567",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236198902174053",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 148,
        "village": "三沱村",
        "type": "残疾人",
        "name": "卢歆瑶",
        "address": "安坪镇三沱村3组143号",
        "lng": "",
        "lat": "",
        "phone": "19115511886",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "500236201508094068",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 149,
        "village": "三沱村",
        "type": "残疾人",
        "name": "樊先龙",
        "address": "安坪镇三坨村3组24号",
        "lng": "",
        "lat": "",
        "phone": "15696518215",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226194510254057",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 150,
        "village": "三沱村",
        "type": "残疾人",
        "name": "冉述富",
        "address": "安坪镇三沱村3组146号",
        "lng": "",
        "lat": "",
        "phone": "15157462593",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "51222619510913407X",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 151,
        "village": "三沱村",
        "type": "残疾人",
        "name": "李启国",
        "address": "安坪镇三沱村1组97号",
        "lng": "",
        "lat": "",
        "phone": "13098623522",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226194504124053",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 152,
        "village": "三沱村",
        "type": "残疾人",
        "name": "张本红",
        "address": "安坪镇三沱村5组154号",
        "lng": "",
        "lat": "",
        "phone": "",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "女",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226197408214060",
        "fp": "否",
        "是否现役军人": "否"
    },
    {
        "number": 153,
        "village": "三沱村",
        "type": "残疾人",
        "name": "冉景东",
        "address": "安坪镇三沱村6组23号",
        "lng": "",
        "lat": "",
        "phone": "",
        "desc": "",
        "phone_1": "",
        "mz": "汉族",
        "sex": "男",
        "mm": "群众",
        "wh": "",
        "people": "",
        "家庭情况": "",
        "idcard": "512226196311254053",
        "fp": "否",
        "是否现役军人": "否"
    }
]

export default people

