<template>
	<div>
		<div class="" style="position: relative;width:100%;height: 100%;">
			<div id="myEcharts3" style="width:100%;height:100%;"></div>
		</div>

	</div>
</template>

<script>
	import * as echarts from 'echarts'
	import 'echarts-gl'
	export default {
		data() {
			return {
				data: []
			}
		},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				let that = this
				let myChart = echarts.init(document.getElementById("myEcharts3"), "purple-passion");
				//随着屏幕大小调节图表
				window.addEventListener("resize", () => {
					myChart.resize();
				});


				var option = {
					// title: {
					// 	text: '用电量'
					// },
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['总产值', '总产量'],
						icon: 'stack',
						itemWidth: 10,
						itemHeight: 10,
						y:0,
						textStyle: {
							color: '#1bb4f6'
						},
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						top: '8%',
						containLabel: true
					},
					toolbox: {
						feature: {
							// saveAsImage: {}
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false, //坐标轴两边留白
						data: ['2018年', '2019年', '2020年', '2021年', '2022年', '2023年'],
						axisLabel: { //坐标轴刻度标签的相关设置。
							interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
							//	margin:15,
							textStyle: {
								color: '#fff',
								fontStyle: 'normal',
								fontFamily: '微软雅黑',
								fontSize: 12,
							},
							// formatter: function(params) {
							// 	var newParamsName = "";
							// 	var paramsNameNumber = params.length;
							// 	var provideNumber = 4; //一行显示几个字
							// 	var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
							// 	if (paramsNameNumber > provideNumber) {
							// 		for (var p = 0; p < rowNumber; p++) {
							// 			var tempStr = "";
							// 			var start = p * provideNumber;
							// 			var end = start + provideNumber;
							// 			if (p == rowNumber - 1) {
							// 				tempStr = params.substring(start, paramsNameNumber);
							// 			} else {
							// 				tempStr = params.substring(start, end) + "\n";
							// 			}
							// 			newParamsName += tempStr;
							// 		}

							// 	} else {
							// 		newParamsName = params;
							// 	}
							// 	return newParamsName
							// },
							//rotate:50,
						},
						axisTick: { //坐标轴刻度相关设置。
							show: false,
						},
						axisLine: { //坐标轴轴线相关设置
							lineStyle: {
								color: '#E5E9ED',
								// opacity:0.2
							}
						},
						splitLine: { //坐标轴在 grid 区域中的分隔线。
							show: false,
							lineStyle: {
								color: '#E5E9ED',
								// 	opacity:0.1
							}
						}
					},
					yAxis: [{
						type: 'value',
						splitNumber: 5,
						axisLabel: {
							textStyle: {
								color: '#a8aab0',
								fontStyle: 'normal',
								fontFamily: '微软雅黑',
								fontSize: 12,
							}
						},
						axisLine: {
							lineStyle: {
								color: '#E5E9ED',
								// opacity:0.2
							}
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: '#E5E9ED',
								// 	opacity:0.1
							}
						}

					}],
					series: [{
							name: '总产值',
							type: 'line',
							itemStyle: {
								normal: {
									color: '#3A84FF',
									lineStyle: {
										color: "#3A84FF",
										width: 1
									},
									areaStyle: {
										color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
											offset: 0,
											color: 'rgba(58,132,255,0)'
										}, {
											offset: 1,
											color: '#01A8FF'
										}]),
									}
								}
							},
							data: [1, 2, 3, 3, 5, 6, 5, 3, 6, 5, 5, 4]
						},
						{
							name: '总产量',
							type: 'line',
							itemStyle: {
								normal: {
									color: 'rgba(255,80,124,1)',
									lineStyle: {
										color: "rgba(255,80,124,1)",
										width: 1
									},
									areaStyle: {
										color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
											offset: 0,
											color: 'rgba(255,80,124,0)'
										}, {
											offset: 1,
											color: '#FF8F1F'
										}]),
									}
								}
							},
							data: [1, 5, 2, 8, 6, 7, 8, 7, 7, 6, 8, 6]
						}
					]
				};


				myChart.setOption(option);
			}
		}
	}
</script>

<style scoped>
	.sycw {
		font-size: 72rpx;
		font-family: DingTalk JinBuTi, DingTalk JinBuTi;
		font-weight: 900;
		color: #FFFFFF;
		text-shadow: 0px 8rpx 20rpx rgba(0, 0, 0, 0.8);
		-webkit-background-clip: text;
		-webkit-text-fill-color: #ffedf6;
	}

	.title {
		font-size: 24rpx;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: #dbecff;
		line-height: 40px;
		margin-left: 20rpx;
	}

	.bg {
		width: 30rpx;
		height: 30rpx;

	}
</style>