<template>
	<div class="">
		<div id="container3" style="height: 100%;width: 100%;" ref="amap"></div>
		<div class="tuli" style="right: 8%;">
			<div class="flex alit justb" v-for="(item,index) in tuli" style="margin-bottom: 10px;cursor: pointer;"
				@click="setNewMarker(item)">
				<div class="flex alit">
					<img :src="item.icon" style="width: 16px;height: 16px;" alt="" />
					<div class="tuli-title">{{item.title}}</div>
				</div>
				<!-- <div class="tuli-num">{{item.num}}</div> -->
			</div>
			<div class="" style="font-size: 20px;color: #fff;text-align: center;margin-bottom: 20px;">脐橙品种</div>
			<div class="flex alit justb" v-for="(item,index) in 4" style="margin-bottom: 10px;cursor: pointer;">
				<div class="flex alit">
					<div class="tuli-cc" style="background-color: #b4cc62;" v-if="index == 0"></div>
					<div class="tuli-cc" style="background-color: #7585cc;" v-if="index == 1"></div>
					<div class="tuli-cc" style="background-color: #cc5974;" v-if="index == 2"></div>
					<div class="tuli-cc" style="background-color: #cc6651;" v-if="index == 3"></div>
					<div class="tuli-title" v-if="index == 0">凤早脐橙</div>
					<div class="tuli-title" v-if="index == 1">凤园脐橙</div>
					<div class="tuli-title" v-if="index == 2">凤晚脐橙</div>
					<div class="tuli-title" v-if="index == 3">红翠2号</div>
				</div>
				<!-- <div class="tuli-num">{{item.num}}</div> -->
			</div>
		</div>
		<!-- <div class="tuli" v-if="nowIndex == 2 || nowIndex == 0" style="right: 5%;">

		</div> -->
	</div>
</template>

<script>
	import AMapLoader from '@amap/amap-jsapi-loader'
	import regionalMaskPath from "./pathArray"
	import regionalMaskPath2 from "./pathArray2"
	import santuoPath from './santuoPath'
	import stPeople from "./stPeople"

	export default {
		components: {},
		props: {
			peopleList: {
				type: Array
			}
		},
		data() {
			return {
				nowIndex: 0,
				map: '',
				infoWindowLayer: '',
				markers: [],
				polygons: [],
				dahuList: [{
						name: '奉节县驰浩水果种植有限公司',
						xl: '450',
						m: 311.9
					},
					{
						name: '奉节县坤双脐橙种植专业合作社',
						xl: '320',
						m: 200
					},
					{
						name: '奉节县显海脐橙种植有限公司',
						xl: '300',
						m: 200
					},
					{
						name: '奉节县赢获生态农业有限公司',
						xl: '550',
						m: 408.5
					},
					{
						name: '奉节县众扬水果种植专业合作社',
						xl: '1600',
						m: 1000
					},
					{
						name: '奉节县紫塘脐橙专业合作社',
						xl: '500',
						m: 350
					}
				],
				tuli: [{
					title: '采摘基地',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E9%87%87%E6%91%98%E5%9F%BA%E5%9C%B0.png',
					type: 9
				}, {
					title: '观景平台',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E8%A7%82%E6%99%AF.png',
					type: 1
				}, {
					title: '景区入口',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%99%AF%E5%8C%BA%E5%85%A5%E5%8F%A3.png',
					type: 2
				}, {
					title: '超市',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E8%B6%85%E5%B8%82.png',
					type: 3
				}, {
					title: '公厕',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E5%85%AC%E5%8E%95.png',
					type: 4
				}, {
					title: '接待中心',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%8E%A5%E5%BE%85%E4%B8%AD%E5%BF%83.png',
					type: 5
				}, {
					title: '民宿',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%B0%91%E5%AE%BF.png',
					type: 6
				}, {
					title: '村卫生室',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E5%8D%AB%E7%94%9F%E5%AE%A4.png',
					type: 7
				}, {
					title: '停车场',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E5%81%9C%E8%BD%A6%E5%9C%BA.png',
					type: 8
				}, {
					title: '种植大户',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E7%A7%8D%E6%A4%8D%E5%A4%A7%E6%88%B7%402x.png',
					type: 10
				}, {
					title: '脐橙产业',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E4%BA%A7%E4%B8%9A%402x.png',
					type: 11
				}, ],
				mapInfo: [{
					title: '宝塔树粱观景平台',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E8%A7%82%E6%99%AFmap.png',
					lnglat: [109.382891, 30.973304],
					type: 1
				}, {
					title: '新码头观景平台',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E8%A7%82%E6%99%AFmap.png',
					lnglat: [109.379849, 30.971121],
					type: 1
				}, {
					title: '黄家包观景平台',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E8%A7%82%E6%99%AFmap.png',
					lnglat: [109.377538, 30.971611],
					type: 1
				}, {
					title: '半边街观景平台',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E8%A7%82%E6%99%AFmap.png',
					lnglat: [109.374997, 30.970655],
					type: 1
				}, {
					title: '柿子坪古遗址观景平台',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E8%A7%82%E6%99%AFmap.png',
					lnglat: [109.388354, 30.972762],
					type: 1
				}, {
					title: '丰河观景平台',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E8%A7%82%E6%99%AFmap.png',
					lnglat: [109.39128, 30.973728],
					type: 1
				}, {
					title: '毛狗堆古遗址观景平台',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E8%A7%82%E6%99%AFmap.png',
					lnglat: [109.394797, 30.9746],
					type: 1
				}, {
					title: '景区入口1',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%99%AF%E5%8C%BA%E5%85%A5%E5%8F%A3map.png',
					lnglat: [109.396342, 30.972682],
					type: 2
				}, {
					title: '景区入口2',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%99%AF%E5%8C%BA%E5%85%A5%E5%8F%A3map.png',
					lnglat: [109.392137, 30.971429],
					type: 2
				}, {
					title: '景区入口3',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%99%AF%E5%8C%BA%E5%85%A5%E5%8F%A3map.png',
					lnglat: [109.389442, 30.970419],
					type: 2
				}, {
					title: '景区入口4',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%99%AF%E5%8C%BA%E5%85%A5%E5%8F%A3map.png',
					lnglat: [109.38377, 30.969136],
					type: 2
				}, {
					title: '景区入口5',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%99%AF%E5%8C%BA%E5%85%A5%E5%8F%A3map.png',
					lnglat: [109.379509, 30.969277],
					type: 2
				}, {
					title: '景延超市',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E8%B6%85%E5%B8%82map.png',
					lnglat: [109.394713, 30.972273],
					type: 3
				}, {
					title: '三沱便民超市',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E8%B6%85%E5%B8%82map.png',
					lnglat: [109.386277, 30.970574],
					type: 3
				}, {
					title: '超舅超市',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E8%B6%85%E5%B8%82map.png',
					lnglat: [109.374817, 30.967992],
					type: 3
				}, {
					title: '公厕',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E5%85%AC%E5%8E%95map.png',
					lnglat: [109.391181, 30.972944],
					type: 4
				}, {
					title: '公厕',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E5%85%AC%E5%8E%95map.png',
					lnglat: [109.386434, 30.970365],
					type: 4
				}, {
					title: '公厕',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E5%85%AC%E5%8E%95map.png',
					lnglat: [109.388623, 30.972011],
					type: 4
				}, {
					title: '公厕',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E5%85%AC%E5%8E%95map.png',
					lnglat: [109.382692, 30.971049],
					type: 4
				}, {
					title: '游客接待中心',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%8E%A5%E5%BE%85%E4%B8%AD%E5%BF%83map.png',
					lnglat: [109.378178, 30.969542],
					type: 5
				}, {
					title: '游客接待中心',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%8E%A5%E5%BE%85%E4%B8%AD%E5%BF%83map.png',
					lnglat: [109.378178, 30.969542],
					type: 5
				}, {
					title: '红橙山庄',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%B0%91%E5%AE%BFmap.png',
					lnglat: [109.375164, 30.969131],
					type: 6
				}, {
					title: '兰姐农家乐',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%B0%91%E5%AE%BFmap.png',
					lnglat: [109.377177, 30.969027],
					type: 6
				}, {
					title: '黄家院子',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%B0%91%E5%AE%BFmap.png',
					lnglat: [109.380416, 30.969245],
					type: 6
				}, {
					title: '江畔橙院',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%B0%91%E5%AE%BFmap.png',
					lnglat: [109.387335, 30.972157],
					type: 6
				}, {
					title: '水驿橙庄度假村',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%B0%91%E5%AE%BFmap.png',
					lnglat: [109.39201, 30.973195],
					type: 6
				}, {
					title: '奉橙农庄',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E6%B0%91%E5%AE%BFmap.png',
					lnglat: [109.397879, 30.970383],
					type: 6
				}, {
					title: '村卫生室',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E5%8D%AB%E7%94%9F%E5%AE%A4map.png',
					lnglat: [109.378546, 30.969518],
					type: 7
				}, {
					title: '白虎嘴停车场',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E5%81%9C%E8%BD%A6%E5%9C%BAmap.png',
					lnglat: [109.374222, 30.966778],
					type: 8
				}, {
					title: '停车场',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E5%81%9C%E8%BD%A6%E5%9C%BAmap.png',
					lnglat: [109.378523, 30.969336],
					type: 8
				}, {
					title: '三沱停车场',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E5%81%9C%E8%BD%A6%E5%9C%BAmap.png',
					lnglat: [109.386471, 30.970125],
					type: 8
				}, {
					title: '丰河停车场',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E5%81%9C%E8%BD%A6%E5%9C%BAmap.png',
					lnglat: [109.388709, 30.970218],
					type: 8
				}, {
					title: '采摘基地',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E9%87%87%E6%91%98map.png',
					lnglat: [109.396337, 30.973954],
					type: 9
				}, {
					title: '采摘基地',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E9%87%87%E6%91%98map.png',
					lnglat: [109.394162, 30.972922],
					type: 9
				}, {
					title: '采摘基地',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E9%87%87%E6%91%98map.png',
					lnglat: [109.386692, 30.972398],
					type: 9
				}, {
					title: '采摘基地',
					icon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E9%87%87%E6%91%98map.png',
					lnglat: [109.378983, 30.971206],
					type: 9
				}],
				nowType: 0
			}
		},
		mounted() {
			let that = this
			setTimeout(function() {
				that.initAMap()
			}, 100);
		},
		beforeDestroy() {
			// 在这里执行需要的清理工作
			this.map.destroy()
		},
		methods: {
			setNewPoline(e) {

				e = 100 + e
				this.map.remove(this.polygons)
				this.map.remove(this.markers)
				if (this.nowType == e) {
					this.nowType = 0
					this.map.remove(this.markers)
					for (var i = 0; i < santuoPath.length; i++) {
						this.setPolineOne(santuoPath[i], i)
					}
					for (var i = 0; i < this.mapInfo.length; i++) {
						this.setPeopleMarker(this.mapInfo[i])
					}
					for (var i = 0; i < this.dahuList.length; i++) {
						this.setPeopleMarker2(this.dahuList[i], i)
					}
					this.setPeopleMarker3()
				} else {
					this.nowType = e
					switch (e) {
						case value:
							break;
						default:
							break;
					}
				}
			},
			setNewMarker(e) {
				this.map.remove(this.polygons)
				this.map.remove(this.markers)
				if (this.nowType == e.type) {
					this.nowType = 0
					this.map.remove(this.markers)
					for (var i = 0; i < santuoPath.length; i++) {
						this.setPolineOne(santuoPath[i], i)
					}
					for (var i = 0; i < this.mapInfo.length; i++) {
						this.setPeopleMarker(this.mapInfo[i])
					}
					for (var i = 0; i < this.dahuList.length; i++) {
						this.setPeopleMarker2(this.dahuList[i], i)
					}
					this.setPeopleMarker3()
				} else {
					this.nowType = e.type
					if (e.type == 10) {
						for (var i = 0; i < this.dahuList.length; i++) {
							this.setPeopleMarker2(this.dahuList[i], i)
						}
					} else if (e.type == 11) {
						this.setPeopleMarker3()
					} else {
						for (var i = 0; i < this.mapInfo.length; i++) {
							if (this.mapInfo[i].type == e.type) {
								this.setPeopleMarker(this.mapInfo[i])
							}
						}
					}

				}
			},
			changeLocation(e) {
				this.nowIndex = e
				this.map.setCenter([109.37643, 30.963761])
				this.map.setZoom(15)
				this.map.remove(this.polygons)
				this.map.remove(this.markers)
				if (e == 2) {
					//这里展示农场的位置
					for (var i = 0; i < santuoPath.length; i++) {
						this.setPolineOne(santuoPath[i], i)
					}
				} else if (e == 3) {
					//这里展示农场的位置
					for (var i = 0; i < this.dahuList.length; i++) {
						this.setPeopleMarker2(this.dahuList[i], i)
					}
				} else if (e == 4) {
					//这里展示农场的位置
					this.setPeopleMarker3()
				} else if (e == 8) {
					//这里展示农场的位置
					for (var i = 0; i < santuoPath.length; i++) {
						this.setPolineOne(santuoPath[i], i)
					}
					//这里展示农场的位置
					for (var i = 0; i < this.dahuList.length; i++) {
						this.setPeopleMarker2(this.dahuList[i], i)
					}
					//这里展示农场的位置
					this.setPeopleMarker3()
				} else {
					for (var i = 0; i < this.mapInfo.length; i++) {
						this.setPeopleMarker(this.mapInfo[i])
					}
				}
			},
			initLocation() {
				this.map.setCenter([109.384995, 30.963833])
				this.map.setZoom(15)
				this.map.remove(this.polygons)
				this.map.remove(this.markers)
				for (var i = 0; i < santuoPath.length; i++) {
					this.setPolineOne(santuoPath[i], i)
				}
				for (var i = 0; i < this.mapInfo.length; i++) {
					this.setPeopleMarker(this.mapInfo[i])
				}
				for (var i = 0; i < this.dahuList.length; i++) {
					this.setPeopleMarker2(this.dahuList[i], i)
				}
				this.setPeopleMarker3()
			},
			setPolineOne(path, i) {
				let color = ['#b4cc62', '#7585cc', '#cc5974', '#cc6651']
				var showColor = ''
				var showTitle = ''
				switch (i) {
					case 0:
					case 1:
						showColor = color[0]
						showTitle = '脐橙品种：凤早脐橙<br/>种植面积:1350亩'
						break;
					case 2:
					case 3:
						showColor = color[1]
						showTitle = '脐橙品种：凤园脐橙<br/>种植面积:1350亩'
						break;
					case 4:
					case 5:
						showColor = color[2]
						showTitle = '脐橙品种：凤晚脐橙<br/>种植面积:1350亩'
						break;
					case 6:
					case 7:
						showColor = color[3]
						showTitle = '脐橙品种：红翠2号<br/>种植面积:1350亩'
						break;
					case 8:
					case 9:
						showColor = color[0]
						showTitle = '脐橙品种：凤早脐橙<br/>种植面积:1350亩'
						break;
					default:
						break;
				}

				let polygon = new AMap.Polygon({
					path: path,
					fillColor: showColor,
					strokeOpacity: 1,
					fillOpacity: 0.8,
					strokeColor: '#2b8cbe',
					strokeWeight: 1,
					strokeStyle: 'dashed',
					strokeDasharray: [5, 5],
				});

				var html = `<div class="infowind2 flex">
							<div class="">
								<div class="info-type" style="font-size:18px;text-align:left">${showTitle}</div>
							</div>
						 </div>`

				//创建信息窗体
				var infoWindow = new AMap.InfoWindow({
					isCustom: true, //使用自定义窗体
					content: html, //信息窗体的内容可以是任意html片段
					offset: new AMap.Pixel(3, -30), //偏移位置
					closeWhenClickMap: false
				});

				polygon.on('mouseover', (e) => {
					infoWindow.open(this.map, e.lnglat) //e.target.getPosition()为marker对应坐标
					this.infoWindowLayer = infoWindow
					polygon.setOptions({
						fillOpacity: 0.7,
						fillColor: '#7bccc4'
					})
				})
				polygon.on('mouseout', () => {
					this.infoWindowLayer.close()
					polygon.setOptions({
						fillOpacity: 0.8,
						fillColor: showColor
					})
				})

				this.map.add(polygon);
				this.polygons.push(polygon)

			},
			initAMap() {
				const _this = this

				AMapLoader.load({
						key: 'b495ac24ca6062feb0025a227065c103', // 申请好的Web端开发者Key，首次调用 load 时必填
						version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
						plugins: [] // 需要使用的的插件列表，如比例尺'AMap.Scale'等，如果是地图控件，必须再 map.addControl添加
					})
					.then((AMap) => {
						var roadNet = new AMap.TileLayer.RoadNet(); //网路
						var googleSatelliteDomestic = new AMap.TileLayer({
							getTileUrl: "https://map.snkoudai.com/maps/vt?lyrs=s@189&gl=cn&x=[x]&y=[y]&z=[z]",
							zooms: [13, 25]
						});
						const map = new AMap.Map('container3', {
							rotateEnable: true, //是否开启地图旋转交互 鼠标右键 + 鼠标画圈移动 或 键盘Ctrl + 鼠标画圈移动
							pitchEnable: true, // 是否开启地图倾斜交互 鼠标右键 + 鼠标上下移动或键盘Ctrl + 鼠标上下移动
							viewMode: "3D", //是否为3D地图模式  //开启偶尔会报错 Invalid Object: Pixel(NaN, NaN)
							center: [109.384995, 30.963833], //初始化地图中心点位置
							terrain: true, // 开启地形图
							pitch: 10.30000000000021, // 地图俯仰角度，有效范围 0 度- 83 度
							zoom: 15, //初始化地图级别  PC上，参数zoom可设范围：[3,18]； 移动端：参数zoom可设范围：[3,19]
							// rotation: -170.900000000000013,
							layers: [
								// satellite,
								// roadNet,
								googleSatelliteDomestic
								// sanTuoDomestic
							],
							terrain: true
						})
						// 添加控件
						AMap.plugin(
							[
								'AMap.ElasticMarker',
								'AMap.Scale',
								'AMap.ToolBar',
								'AMap.HawkEye',
								'AMap.MapType',
								'AMap.Geolocation',
							],
							() => {
								map.addControl(new AMap.ElasticMarker()) // map.addControl为地图添加对应的控件
								// map.addControl(new AMap.Scale())
								// map.addControl(new AMap.ToolBar())
								// map.addControl(new AMap.HawkEye())
								// map.addControl(new AMap.MapType())
								map.addControl(new AMap.Geolocation())
							}
						)
						_this.map = map

						_this.event = (ev) => {
							console.log('您移动鼠标到了 [ ' + ev.lnglat.getLng() + ',' + ev.lnglat.getLat() + ' ]')
							// console.log("当前地图Pitch俯仰角度参数为：", this.map.getPitch());
							// console.log("当前地图Zoom初始化地图级别参数为：", this.map.getZoom());
							// console.log("当前地图Rotation初始化顺时针旋转角度参数为：", this.map.getRotation());
							// this.roadPath.push([ev.lnglat.getLng(), ev.lnglat.getLat()])
							// console.log("最新：", this.roadPath);
							// if (this.infoWindow) {
							// 	this.infoWindow.close()
							// }
						}
						_this.map.on('click', _this.event)

						var polygon = new AMap.Polygon({
							path: regionalMaskPath,
							// extrusionHeight:1,//	设置多边形是否拉伸为的立面体高度值，默认值为0
							strokeColor: '#74d5b2',
							strokeWeight: 0,
							strokeOpacity: 1,
							fillColor: '#000000',
							fillOpacity: 0.4,
							// lineJoin: 'round', //折线拐点的绘制样式，默认值为'miter'尖角，其他可选值：'round'圆角、'bevel'斜角
							// lineCap: 'round', //折线两端线帽的绘制样式，默认值为'butt'无头，其他可选值：'round'圆头、'square'方头
							// zIndex: 2 // 设置遮罩线的层级
							// fillColor: ['#FF0000', '#00FF00']  // 从红色渐变到绿色
						});
						_this.map.add(polygon);

						// var Polyline = new AMap.Polyline({
						// 	path: regionalMaskPath2[1],
						// 	extrusionHeight: 1, //	设置多边形是否拉伸为的立面体高度值，默认值为0
						// 	strokeColor: '#74d5b2',
						// 	strokeOpacity: 1,
						// 	isOutline: true,
						// 	borderWeight: 1,
						// 	outlineColor: '#74d5b2',
						// 	// lineJoin:'round',
						// 	lineCap: 'round',
						// 	zIndex: 50 // 设置遮罩线的层级
						// 	// strokeWeight: 5,
						// 	// fillColor: '#000000',
						// });
						// _this.map.add(Polyline);



						// 创建一个图片覆盖物实例
						const imageLayer = new AMap.ImageLayer({
							url: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/59094b115d8e5136183f0b585434099.png', // 替换为你的图片地址
							bounds: new AMap.Bounds(
								[109.347, 30.984496],
								[109.426204, 30.939063]
							), // 设置图片覆盖物的边界，确保它贴合地图
							zIndex: 99,
						});

						let bounds = imageLayer.getBounds()
						// 将图片覆盖物添加到地图上
						_this.map.add(imageLayer);
						// var marker = new AMap.Marker({
						// 	position: [bounds.northEast.lng + (bounds.northEast.lng - bounds.southWest.lng),
						// 		bounds
						// 		.northEast.lat + (bounds.northEast.lat - bounds.southWest.lat)
						// 	], //位置
						// })
						// _this.map.add(marker);

						for (var i = 0; i < santuoPath.length; i++) {
							this.setPolineOne(santuoPath[i], i)
						}
						for (var i = 0; i < this.mapInfo.length; i++) {
							this.setPeopleMarker(this.mapInfo[i])
						}
						for (var i = 0; i < this.dahuList.length; i++) {
							this.setPeopleMarker2(this.dahuList[i], i)
						}
						this.setPeopleMarker3()

					})
					.catch((e) => {
						console.log(e)
					})
			},
			setPeopleMarker(info) {
				// 点标记显示内容，HTML要素字符串
				var markerContent = '' +
					`<div class="custom-content-marker2" :style="{backgroundImage:url(${info.icon})}">
						<img src="${info.icon}" />
					</div>`;

				var marker = new AMap.Marker({
					position: info.lnglat ? info.lnglat : this.generateRandomLocation(109.399587, 109.373441,
						30.969886, 30.972041),
					// 将 html 传给 content  this.generateRandomLocation(109.399587, 109.373441, 30.956913, 30.970421),
					content: markerContent,
					// 以 icon 的 [center bottom] 为原点
					offset: new AMap.Pixel(-16, -80)
				});

				this.map.add(marker);
				this.markers.push(marker)

				var html =
					// <img src="${info.icon}" alt="" />
					// <div class="info-num">${info.job}</div>
					// <div class="info-name">${info.desc}</div>
					`<div class="infowind2 flex">
						<div class="">
							<div class="info-type">${info.title}</div>
							
						</div>
					 </div>`

				//创建信息窗体
				var infoWindow = new AMap.InfoWindow({
					isCustom: true, //使用自定义窗体
					content: html, //信息窗体的内容可以是任意html片段
					offset: new AMap.Pixel(3, -80), //偏移位置
					closeWhenClickMap: false
				});

				//marker移动到上面展示项目概况信息
				marker.on('mouseover', (e) => {


					infoWindow.open(this.map, e.target.getPosition()) //e.target.getPosition()为marker对应坐标
					this.infoWindowLayer = infoWindow
					console.log("移动到上面   ", this.infoWindowLayer);
				});

				//移动开关闭
				marker.on('mouseout', (e) => {
					this.infoWindowLayer.close()
				})

			},
			setPeopleMarker2(info) {
				// 点标记显示内容，HTML要素字符串
				var markerContent = '' +
					`<div class="custom-content-marker3" :style="{backgroundImage:url(https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E7%A7%8D%E6%A4%8D%E5%A4%A7%E6%88%B7%402x.png)}">
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E7%A7%8D%E6%A4%8D%E5%A4%A7%E6%88%B7%402x.png" />
					</div>`;

				var marker = new AMap.Marker({
					position: this.generateRandomLocation(109.399587, 109.373441, 30.956913, 30.970421),
					// 将 html 传给 content  this.generateRandomLocation(109.399587, 109.373441, 30.956913, 30.970421),
					content: markerContent,
					// 以 icon 的 [center bottom] 为原点
					offset: new AMap.Pixel(-16, -80)
				});

				this.map.add(marker);
				this.markers.push(marker)

				var html =
					// <img src="${info.icon}" alt="" />
					// <div class="info-num">${info.job}</div>
					// <div class="info-name">${info.desc}</div>
					`<div class="infowind2 flex">
						<div class="">
							<div class="info-type" style="font-size:18px;text-align:left">种植大户：${info.name}</div>
							<div class="info-type" style="font-size:18px;text-align:left">销售额：${info.xl}万元</div>
							<div class="info-type" style="font-size:18px;text-align:left">种植面积：${info.m}亩</div>
						</div>
					 </div>`

				//创建信息窗体
				var infoWindow = new AMap.InfoWindow({
					isCustom: true, //使用自定义窗体
					content: html, //信息窗体的内容可以是任意html片段
					offset: new AMap.Pixel(3, -80), //偏移位置
					closeWhenClickMap: false
				});

				//marker移动到上面展示项目概况信息
				marker.on('mouseover', (e) => {
					infoWindow.open(this.map, e.target.getPosition()) //e.target.getPosition()为marker对应坐标
					this.infoWindowLayer = infoWindow
				});

				//移动开关闭
				marker.on('mouseout', (e) => {
					this.infoWindowLayer.close()
				})

			},
			setPeopleMarker3(info) {
				// 点标记显示内容，HTML要素字符串
				var markerContent = '' +
					`<div class="custom-content-marker3">
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/map/%E4%BA%A7%E4%B8%9A%402x.png" />
					</div>`;

				var marker = new AMap.Marker({
					position: [109.385707, 30.97015],
					// 将 html 传给 content  this.generateRandomLocation(109.399587, 109.373441, 30.956913, 30.970421),
					content: markerContent,
					// 以 icon 的 [center bottom] 为原点
					offset: new AMap.Pixel(-20, -40)
				});

				this.map.add(marker);
				this.markers.push(marker)

				var html =
					// <img src="${info.icon}" alt="" />
					// <div class="info-num">${info.job}</div>
					// <div class="info-name">${info.desc}</div>
					`<div class="infowind2 flex">
						<div class="">
							<div class="info-type" style="font-size:18px;text-align:left">果酒（醉白帝）产业</div>
						</div>
					 </div>`

				//创建信息窗体
				var infoWindow = new AMap.InfoWindow({
					isCustom: true, //使用自定义窗体
					content: html, //信息窗体的内容可以是任意html片段
					offset: new AMap.Pixel(3, -80), //偏移位置
					closeWhenClickMap: false
				});

				//marker移动到上面展示项目概况信息
				marker.on('mouseover', (e) => {
					infoWindow.open(this.map, e.target.getPosition()) //e.target.getPosition()为marker对应坐标
					this.infoWindowLayer = infoWindow
				});

				//移动开关闭
				marker.on('mouseout', (e) => {
					this.infoWindowLayer.close()
				})

			},
			generateRandomLocation(minLng, maxLng, minLat, maxLat) {
				// 确保经度有效
				let lng = minLng + (maxLng - minLng) * Math.random();
				// 确保纬度在有效范围内
				let lat = minLat + (maxLat - minLat) * Math.random();
				// 检查是否超出范围
				if (lng > 180) lng -= 360;
				if (lat > 90) lat = 180 - lat;
				if (lat < -90) lat = -180 - lat;
				return [lng, lat];
			}
		}
	}
</script>

<style>
	.tuli-cc {
		width: 30px;
		height: 10px;
	}

	.tuli-num {
		font-weight: 400;
		font-size: 12px;
		color: #FFFFFF;
		text-align: right;
	}

	.tuli-title {
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		margin: 0 40px 0 10px;
	}

	.tuli {
		background: rgba(20, 47, 105, 0.4);
		position: fixed;
		z-index: 999;
		right: 10%;
		bottom: 15% !important;
		padding: 20px;
	}

	.info-num {
		font-weight: 400;
		font-size: 14px;
		color: #999999;
		margin-top: 5px;
	}

	.info-name {
		font-weight: 400;
		font-size: 15px;
		color: #FFFFFF;
		margin: 14px 0;
		width: 185px;
	}

	.info-type {
		font-weight: 400;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		width: 100%;
	}

	.infowind2 {
		width: 209px;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/summary/%E5%AE%B9%E5%99%A8%201.png');
		background-size: 100% 105%;
		padding: 30px;
	}

	.infowind2 img {
		width: 80px;
		height: 100px;
		margin-right: 10px;
	}

	.custom-content-marker3 {
		position: relative;
		width: 40px;
		height: 40px;
		background-size: 100% 100%;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		text-align: right;
		line-height: 35px;
		padding-right: 10px;
	}

	.custom-content-marker3 img {
		width: 100%;
		height: 100%;
	}

	.custom-content-marker2 {
		position: relative;
		width: 32px;
		height: 80px;
		background-size: 100% 100%;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		text-align: right;
		line-height: 35px;
		padding-right: 10px;
	}

	.custom-content-marker2 img {
		width: 100%;
		height: 100%;
	}

	.custom-content-marker2 .close-btn {
		position: absolute;
		top: -6px;
		right: -8px;
		width: 15px;
		height: 15px;
		font-size: 12px;
		background: #ccc;
		border-radius: 50%;
		color: #fff;
		text-align: center;
		line-height: 15px;
		box-shadow: -1px 1px 1px rgba(10, 10, 10, .2);
	}

	.custom-content-marker2 .close-btn:hover {
		background: #666;
	}
</style>