// 区域掩膜路径
const regionalMaskPath = [
	[
		[-360, 90],
		[-360, -90],
		[360, -90],
		[360, 90]
	],
	[
		[109.347, 30.984496],
		[109.4262, 30.984497],
		[109.426204, 30.939063],
		[109.346999, 30.93907]
	]
	// 绘制自定义轮廓,[109.392941,30.965933] [左下，左上、右上，右下]
	// [
	// 	["109.374796", "30.972838"],
	// 	["109.374665", "30.972452"],
	// 	["109.37459", "30.972179"],
	// 	["109.374552", "30.97197"],
	// 	["109.374552", "30.971697"],
	// 	["109.374552", "30.971375"],
	// 	["109.374534", "30.970925"],
	// 	["109.374684", "30.970394"],
	// 	["109.374702", "30.969944"],
	// 	["109.374815", "30.969703"],
	// 	["109.374927", "30.969622"],
	// 	["109.375077", "30.969397"],
	// 	["109.375227", "30.969124"],
	// 	["109.375152", "30.968883"],
	// 	["109.375189", "30.968086"],
	// 	["109.375339", "30.96754"],
	// 	["109.375414", "30.966912"],
	// 	["109.375864", "30.966607"],
	// 	["109.376277", "30.966494"],
	// 	["109.376558", "30.966462"],
	// 	["109.377477", "30.966462"],
	// 	["109.378452", "30.96643"],
	// 	["109.379315", "30.966382"],
	// 	["109.380215", "30.966189"],
	// 	["109.38059", "30.96598"],
	// 	["109.381152", "30.965932"],
	// 	["109.382165", "30.966124"],
	// 	["109.382953", "30.966366"],
	// 	["109.383572", "30.966591"],
	// 	["109.384059", "30.9668"],
	// 	["109.385166", "30.966977"],
	// 	["109.386339", "30.966848"],
	// 	["109.387822", "30.9668"],
	// 	["109.388572", "30.96717"],
	// 	["109.390016", "30.967604"],
	// 	["109.391273", "30.967845"],
	// 	["109.391948", "30.968504"],
	// 	["109.392398", "30.969164"],
	// 	["109.392492", "30.969598"],
	// 	["109.39266", "30.970257"],
	// 	["109.392642", "30.971141"],
	// 	["109.392942", "30.972299"],
	// 	["109.392773", "30.97291"],
	// 	["109.392041", "30.97365"],
	// 	["109.390916", "30.974422"],
	// 	["109.390129", "30.975"],
	// 	["109.388703", "30.975113"],
	// 	["109.387747", "30.975499"],
	// 	["109.385778", "30.975676"],
	// 	["109.383865", "30.975724"],
	// 	["109.382777", "30.975692"],
	// 	["109.381165", "30.975579"],
	// 	["109.380246", "30.975338"],
	// 	["109.379683", "30.975065"],
	// 	["109.378145", "30.974904"],
	// 	["109.377545", "30.974598"],
	// 	["109.376983", "30.974293"],
	// 	["109.37597", "30.973714"],
	// 	["109.375107", "30.97328"],
	// 	["109.374796", "30.972838"],
	// ]
]
export default regionalMaskPath;