<template>
	<div class="">
		<santuoMap style="height: 100%;width: 100%;" ref="map"></santuoMap>

		<div class="botto flex justc alit">
			<img :src="menuIndex == 1 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012625%402x.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012623%402x.png'"
				class="menu" alt="" @click="changeMenuIndex(1)" />
			<img :src="menuIndex == 2 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012627%402x.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012626%402x.png'"
				class="menu" alt="" @click="changeMenuIndex(2)" />
		</div>

		<div class="right flexCol">
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/summary/%E5%AE%B9%E5%99%A8%20323%402x.png"
				style="width: 110px;height: 93px;cursor: pointer;" alt="" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/summary/%E5%AE%B9%E5%99%A8%20344%402x.png"
				style="width: 110px;height: 93px;margin-top: 20px;cursor: pointer;" alt="" />
		</div>


		<div class="popup-info" v-if="menuIndex == 1">
			<img src="../../static/close2.png" class="popup-close" style="width: 30px;height: 30px;" alt=""
				@click="changeMenuIndex(1)" />
			<div class="flexCol alit" v-if="menuIndex == 1">
				<div class="info-title" style="margin: 5% 0 5% 0;">三沱简介</div>
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/summary/d694f750eb9b1a052c2d4b7a91ad235.jpg"
					style="width: 475px;margin-bottom:10px;height: 200px;" alt="" />
				<div class="info-jj">
					三沱村是奉节县知名的脐橙特色产业村和移民新村，海拔175--850米，距离县城约19公里。全村幅员面积7.98平方公里，辖6个村民小组，942户2803人。优越的地理位置、立体的气候、充足的日照使三沱村形成了以脐橙为主导的特色产业，全村脐橙种植面积6000余亩，脐橙树约有30万棵，年产值超1亿元以上，占全村经济收入总量的80%，村民年户均收入达10万元以上。
				</div>
				<div class="info-jj">
					近年来，三沱村围绕“三峡橙庄·水驿三沱”定位，将全村划分为“渔民艺术区、数字脐橙区、生态康养区、观光度假区”四大区域，是全国“建党百年红色旅游百条精品线路”之一。三沱村先后获得全国乡村治理示范村、全国“一村一品”示范村、全国特色产业超亿元村、中国美丽休闲乡村、重庆市脱贫攻坚先进集体、重庆市绿色示范村等20余项国家、市、县级荣誉。
				</div>

				<div class="info-title" style="margin-bottom:2%;margin-top:3%;">乡村荣誉</div>
				<el-carousel :interval="4000" type="card" height="150px" style="width: 500px;">
					<el-carousel-item v-for="item in ryList" :key="item">
						<img :src="item" style="width: 100%;height:  100%;" alt="" />
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<div class="popup-info" v-if="menuIndex == 2" style="height:45%;">
			<img src="../../static/close2.png" class="popup-close" style="width: 30px;height: 30px;" alt=""
				@click="changeMenuIndex(2)" />
			<div class="flexCol alit" v-if="menuIndex == 2">
				<div class="info-title" style="margin: 5% 0 5% 0;">三沱宣传片</div>
				<video ref="video"
					src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/home/ry/%E5%AE%89%E5%9D%AA%E9%95%87%E4%B8%89%E6%B2%B1%E6%9D%91%EF%BC%885%E5%88%86%E9%92%9F%E7%A7%BB%E6%B0%91%E7%B2%BE%E7%A5%9E%E7%89%88%EF%BC%89.m4v"
					style="width: 475px;" object-fit="cover" class="video" :controls="controls"
					:show-center-play-btn="false" @webkitfullscreenchange="onFullScreenChange"
					@fullscreenchange="onFullScreenChange"></video>
			</div>
		</div>

	</div>
</template>

<script>
	import santuoMap from '../components/map.vue'
	export default {
		components: {
			santuoMap
		},
		data() {
			return {
				controls: true,
				menuIndex: 0,
				ryList: ['https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/home/ry/ry1.jpeg',
					'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/home/ry/ry2.jpeg',
					'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/home/ry/ry3.jpeg',
					'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/home/ry/ry4.jpeg'
				]
			}
		},
		mounted() {

		},
		methods: {
			onFullScreenChange() {
				// 检查视频是否退出全屏状态
				// if (!this.$refs.video.isFullscreen) {
				// 	this.$refs.video.pause()
				// 	// 在这里执行退出全屏后的操作
				// }
			},
			changeMenuIndex(e) {
				// if (e == 2) {
				// 	const videoElement = this.$refs.video; // 获取视频元素引用
				// 	if (!document.fullscreenElement) { // 检查是否已全屏

				// 		if (videoElement.requestFullscreen) { // 如果支持全屏方法，则请求全屏
				// 			videoElement.requestFullscreen();
				// 		} else if (videoElement.mozRequestFullScreen) { // 对于旧版Firefox浏览器使用moz前缀的方法
				// 			videoElement.mozRequestFullScreen();
				// 		} else if (videoElement.webkitRequestFullscreen) { // 对于旧版Chrome浏览器使用webkit前缀的方法
				// 			videoElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
				// 		}
				// 		setTimeout(function() {
				// 			videoElement.play()
				// 		}, 200);
				// 	} else {
				// 		if (document.exitFullscreen) { // 如果当前已全屏，则退出全屏模式
				// 			document.exitFullscreen();
				// 		}
				// 	}
				// 	return;
				// }

				if (this.menuIndex == e) {
					this.menuIndex = 0
					this.$refs.map.initLocation()
				} else {
					this.menuIndex = e
					this.$refs.map.changeLocation()
				}

			}
		}
	}
</script>

<style>
	.el-carousel__indicators--outside {
		display: none;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}

	.info-jj {
		width: 475px;
		font-weight: 400;
		font-size: 12px;
		color: #FFFFFF;
		text-indent: 2ch;
		line-height: 20px;
	}

	.info-title {
		font-weight: 700;
		font-size: 20px;
		color: #FFFFFF;
	}



	.right {
		position: fixed;
		right: 30px;
		z-index: 99;
		top: 100px;
	}

	.botto {
		width: 100%;
		position: fixed;
		bottom: 20px;
	}

	.bott-title {
		font-weight: 400;
		font-size: 36px;
		letter-spacing: 6px;
		text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
		/* background: linear-gradient(89.47397181783094deg, #FFFFFF 0%, #9DD8FF 100%); */
	}

	.menu {
		width: 350px;
		height: 65px;
		cursor: pointer;
		margin-left: 10px;

	}
</style>