<template>
	<div>
		<div class="" style="position: relative;width:100%;height: 100%;">
			<div id="myEcharts2" style="width:100%;height:100%;"></div>
		</div>

	</div>
</template>

<script>
	import * as echarts from 'echarts'
	import 'echarts-gl'
	export default {
		data() {
			return {
				data: []
			}
		},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				let that = this
				let myChart = echarts.init(document.getElementById("myEcharts2"), "purple-passion");
				//随着屏幕大小调节图表
				window.addEventListener("resize", () => {
					myChart.resize();
				});

				var option = {
					"color": ["#3cefff"],
					"tooltip": {},
					grid: {
						left: '10%',
						right: '10%',
						top: '24%',
						bottom: '2%',
						containLabel: true
					},
					"xAxis": [{
						"type": "category",
						"data": ["01月", "02月", "03月", "04月", "05月", "06月", "07月", "08月"],
						"axisTick": {
							"alignWithLabel": true
						},
						"nameTextStyle": {
							"color": "#82b0ec"
						},
						"axisLine": {
							"lineStyle": {
								"color": "#82b0ec"
							}
						},
						"axisLabel": {
							"textStyle": {
								"color": "#82b0ec"
							}
						}
					}],
					"yAxis": [{
						"type": "value",
						"axisLabel": {
							"textStyle": {
								"color": "#82b0ec"
							},
							"formatter": "{value}%"
						},
						"splitLine": {
							"lineStyle": {
								"color": "#0c2c5a"
							}
						},
						"axisLine": {
							"show": false
						}
					}],
					"series": [{
							"name": "",
							"type": "pictorialBar",
							"symbolSize": [20, 10],
							"symbolOffset": [0, -5],
							"symbolPosition": "end",
							"z": 12,
							"label": {
								"normal": {
									"show": true,
									"position": "top",
									"formatter": "{c}"
								}
							},
							"data": [60, 70, 80, 90, 60, 70, 80, 90]
						}, {
							"name": "",
							"type": "pictorialBar",
							"symbolSize": [20, 10],
							"symbolOffset": [0, 5],
							"z": 12,
							"data": [60, 70, 80, 90, 60, 70, 80, 90]
						}, {
							"type": "bar",
							"itemStyle": {
								"normal": {
									"opacity": 0.7
								}
							},
							"barWidth": "20",
							"data": [60, 70, 80, 90, 60, 70, 80, 90],
							"markLine": {

							}
						},
						{
							type: 'effectScatter',
							silent: true,
							tooltip: {
								show: false
							},
							zlevel: 3,
							symbolSize: 10,
							showEffectOn: 'render',
							rippleEffect: {
								brushType: 'stroke',
								color: '#3cefff',
								scale: 5
							},
							itemStyle: {
								color: '#3cefff'
							},
							hoverAnimation: true,
							data: [0, 0, 0, 0, 0, 0, 0, 0]
						},
					]
				}

				myChart.setOption(option);
			}
		}
	}
</script>

<style scoped>
	.sycw {
		font-size: 72rpx;
		font-family: DingTalk JinBuTi, DingTalk JinBuTi;
		font-weight: 900;
		color: #FFFFFF;
		text-shadow: 0px 8rpx 20rpx rgba(0, 0, 0, 0.8);
		-webkit-background-clip: text;
		-webkit-text-fill-color: #ffedf6;
	}

	.title {
		font-size: 24rpx;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: #dbecff;
		line-height: 40px;
		margin-left: 20rpx;
	}

	.bg {
		width: 30rpx;
		height: 30rpx;

	}
</style>