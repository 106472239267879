export default {
	formatCurrency(num) {
		//数字金额按每三位数字加逗号金额必须为带分  12300 00代表到分
		num = (num / 100).toFixed(2)
		return num.replace(/\d(?=(\d{3})+\.)/g, '$&,');
	},
	formatCurrency2(num) {
		//数字金额按每三位数字加逗号金额不带分  123
		var result = [],
			counter = 0;
		num = (num || 0).toString().split('');
		for (var i = num.length - 1; i >= 0; i--) {
			counter++;
			result.unshift(num[i]);
			if (!(counter % 3) && i != 0) {
				result.unshift(',');
			}
		}
		return result.join('');
	}
}