import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import home from '../views/home.vue'
import summary from '../views/summary.vue'
import partyBuilding from '../views/partyBuilding.vue'
import tourism from '../views/tourism.vue'
import supervise from '../views/supervise.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'HomeView',
	component: HomeView,
	children: [{
			path: '/',
			name: 'home',
			component: home,
		}, {
			path: '/summary',
			name: 'summary',
			component: summary,
		}, {
			path: '/partyBuilding',
			name: 'partyBuilding',
			component: partyBuilding,
		}, {
			path: '/tourism',
			name: 'tourism',
			component: tourism,
		}, {
			path: '/supervise',
			name: 'supervise',
			component: supervise,
		},



	],
}]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router