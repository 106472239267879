const regionalMaskPath = [
	[
		[109.37594, 30.971071],
		[109.375746, 30.971051],
		[109.375722, 30.970947],
		[109.375601, 30.970864],
		[109.37554, 30.97076],
		[109.375613, 30.970666],
		[109.375686, 30.970583],
		[109.375783, 30.970531],
		[109.375904, 30.970469],
		[109.376098, 30.970438],
		[109.376364, 30.970438],
		[109.376789, 30.970438],
		[109.377055, 30.970438],
		[109.377164, 30.97051],
		[109.377334, 30.970583],
		[109.377564, 30.970593],
		[109.377734, 30.970593],
		[109.377988, 30.970625],
		[109.378219, 30.970645],
		[109.37834, 30.970687],
		[109.378558, 30.970728],
		[109.378655, 30.97077],
		[109.378825, 30.970843],
		[109.378885, 30.970978],
		[109.378837, 30.971134],
		[109.378752, 30.971217],
		[109.378582, 30.9713],
		[109.378376, 30.971373],
		[109.378158, 30.971487],
		[109.378049, 30.971549],
		[109.377904, 30.971612],
		[109.377722, 30.971612],
		[109.377516, 30.971633],
		[109.377383, 30.971633],
		[109.377237, 30.971601],
		[109.377128, 30.971497],
		[109.377043, 30.971456],
		[109.376898, 30.971362],
		[109.37674, 30.971269],
		[109.376607, 30.971207],
		[109.376352, 30.971186],
		[109.376171, 30.971144],
		[109.376122, 30.971165],
		[109.375977, 30.971092],
	],
	[
		[109.385588, 30.972305],
		[109.385511, 30.9722],
		[109.385434, 30.972108],
		[109.385304, 30.972075],
		[109.385234, 30.971996],
		[109.385257, 30.971818],
		[109.385304, 30.971759],
		[109.385342, 30.971726],
		[109.385472, 30.971699],
		[109.385519, 30.971693],
		[109.385664, 30.97168],
		[109.385856, 30.97168],
		[109.386033, 30.971699],
		[109.386217, 30.971739],
		[109.386471, 30.971746],
		[109.38674, 30.971739],
		[109.386947, 30.971739],
		[109.387216, 30.971739],
		[109.387523, 30.971739],
		[109.387815, 30.971759],
		[109.387899, 30.971831],
		[109.388053, 30.971851],
		[109.388268, 30.971871],
		[109.388398, 30.971983],
		[109.388437, 30.972035],
		[109.388506, 30.972101],
		[109.388513, 30.972272],
		[109.388513, 30.97243],
		[109.388498, 30.972575],
		[109.388498, 30.972694],
		[109.388406, 30.97274],
		[109.388245, 30.972792],
		[109.388114, 30.972792],
		[109.387937, 30.972779],
		[109.387769, 30.972694],
		[109.387623, 30.972628],
		[109.387431, 30.972588],
		[109.387208, 30.972536],
		[109.3871, 30.972542],
		[109.387039, 30.972562],
		[109.38677, 30.972516],
		[109.386724, 30.97243],
		[109.386593, 30.972384],
		[109.386455, 30.972391],
		[109.38611, 30.972397],
		[109.385979, 30.972384],
		[109.385772, 30.972345]
	],
	[
		[109.382789, 30.968026],
		[109.38341, 30.967981],
		[109.384009, 30.968107],
		[109.384388, 30.96836],
		[109.385209, 30.968369],
		[109.385619, 30.968432],
		[109.385998, 30.968306],
		[109.386198, 30.967972],
		[109.386397, 30.967719],
		[109.386482, 30.967358],
		[109.386334, 30.967142],
		[109.386176, 30.966971],
		[109.385156, 30.966962],
		[109.38463, 30.96679],
		[109.383851, 30.966772],
		[109.38341, 30.966772],
		[109.382431, 30.966898],
		[109.382242, 30.967346],
		[109.382221, 30.967614],
		[109.382431, 30.96783],
		[109.382557, 30.967966],
		[109.382673, 30.967975]
	],
	[
		[109.38245, 30.965314],
		[109.382239, 30.96498],
		[109.382187, 30.964542],
		[109.382429, 30.964458],
		[109.382723, 30.964458],
		[109.38285, 30.96453],
		[109.382965, 30.964729],
		[109.383039, 30.964909],
		[109.383029, 30.965144],
		[109.382766, 30.965279],
		[109.382587, 30.965351]
	],
	[
		[109.386454, 30.959405],
		[109.385753, 30.959214],
		[109.385356, 30.959234],
		[109.385029, 30.959355],
		[109.38476, 30.959575],
		[109.384666, 30.959866],
		[109.384725, 30.960136],
		[109.385134, 30.960397],
		[109.385391, 30.960567],
		[109.385566, 30.960828],
		[109.385952, 30.960908],
		[109.386255, 30.961098],
		[109.386664, 30.961239],
		[109.387482, 30.961289],
		[109.388102, 30.961239],
		[109.388441, 30.961118],
		[109.388835, 30.960637],
		[109.389057, 30.960487],
		[109.388951, 30.960307],
		[109.388753, 30.960196],
		[109.388426, 30.960006],
		[109.388192, 30.959866],
		[109.387713, 30.959735],
		[109.387421, 30.959585],
		[109.386907, 30.959555],
		[109.386369, 30.959345]
	],
	[
		[109.392153, 30.96726],
		[109.391744, 30.967],
		[109.391534, 30.966789],
		[109.391265, 30.966619],
		[109.391055, 30.966459],
		[109.390938, 30.966288],
		[109.390704, 30.966188],
		[109.390459, 30.966108],
		[109.390155, 30.966018],
		[109.389921, 30.965958],
		[109.389617, 30.965858],
		[109.389734, 30.965687],
		[109.389839, 30.965667],
		[109.390412, 30.965687],
		[109.390622, 30.965747],
		[109.390903, 30.965677],
		[109.390961, 30.965577],
		[109.39095, 30.965417],
		[109.391195, 30.965387],
		[109.391452, 30.965317],
		[109.392083, 30.965407],
		[109.392759, 30.965687],
		[109.392923, 30.965898],
		[109.393098, 30.966158],
		[109.393215, 30.966539],
		[109.393238, 30.9669],
		[109.393086, 30.96724],
		[109.392946, 30.967471],
		[109.392619, 30.967671],
		[109.392338, 30.967701],
		[109.39221, 30.967701],
		[109.392116, 30.967421]
	],
	[
		[109.400466, 30.964092],
		[109.400622, 30.964782],
		[109.400843, 30.965016],
		[109.400856, 30.965249],
		[109.400713, 30.96555],
		[109.400492, 30.965728],
		[109.399675, 30.965828],
		[109.398455, 30.965572],
		[109.397676, 30.965038],
		[109.396656, 30.963084],
		[109.397201, 30.962644],
		[109.397551, 30.962611],
		[109.398045, 30.9627],
		[109.398278, 30.962978],
		[109.398551, 30.963178],
		[109.399148, 30.963368],
		[109.399667, 30.963623],
		[109.40016, 30.963879],
		[109.400433, 30.964058]
	],
	[
		[109.391127, 30.957881],
		[109.390842, 30.95767],
		[109.390673, 30.957491],
		[109.390517, 30.957324],
		[109.390375, 30.957191],
		[109.390375, 30.957024],
		[109.39005, 30.956946],
		[109.389752, 30.956946],
		[109.389271, 30.956946],
		[109.388908, 30.956846],
		[109.388415, 30.956701],
		[109.388337, 30.956267],
		[109.388415, 30.9558],
		[109.389116, 30.955444],
		[109.390219, 30.95521],
		[109.392321, 30.955043],
		[109.392866, 30.955277],
		[109.393165, 30.955622],
		[109.393178, 30.956022],
		[109.393087, 30.956301],
		[109.39275, 30.956501],
		[109.392321, 30.956534],
		[109.392062, 30.956712],
		[109.391841, 30.956746],
		[109.391711, 30.956991],
		[109.391582, 30.957191],
		[109.391504, 30.957302],
		[109.391218, 30.957436],
		[109.391205, 30.957636],
		[109.391166, 30.95787]
	],
	[
		[109.38068, 30.956757],
		[109.380875, 30.956968],
		[109.381095, 30.957069],
		[109.381485, 30.956946],
		[109.381679, 30.957035],
		[109.381952, 30.957358],
		[109.381991, 30.957592],
		[109.381679, 30.957647],
		[109.38094, 30.95777],
		[109.380602, 30.957859],
		[109.379901, 30.95767],
		[109.379174, 30.957258],
		[109.378733, 30.956588],
		[109.378707, 30.956109],
		[109.378642, 30.95533],
		[109.378772, 30.954941],
		[109.378941, 30.954395],
		[109.379525, 30.954429],
		[109.380875, 30.954484],
		[109.38129, 30.954418],
		[109.381601, 30.954518],
		[109.381822, 30.954651],
		[109.381861, 30.954752],
		[109.381796, 30.955063],
		[109.381913, 30.955387],
		[109.382121, 30.955675],
		[109.382185, 30.956265],
		[109.38177, 30.956254],
		[109.381459, 30.956154],
		[109.38094, 30.95612],
		[109.380784, 30.956287],
		[109.380628, 30.956499]
	],
	[
		[109.378834, 30.961476],
		[109.378341, 30.961109],
		[109.378172, 30.960775],
		[109.378302, 30.960564],
		[109.378444, 30.960185],
		[109.378444, 30.960007],
		[109.378237, 30.959852],
		[109.377964, 30.959707],
		[109.37764, 30.95944],
		[109.377354, 30.959228],
		[109.37703, 30.959039],
		[109.376628, 30.958805],
		[109.376433, 30.958728],
		[109.376316, 30.959039],
		[109.375875, 30.959239],
		[109.375602, 30.959362],
		[109.375576, 30.959729],
		[109.375693, 30.960274],
		[109.375784, 30.960753],
		[109.375992, 30.961432],
		[109.376186, 30.961988],
		[109.376589, 30.9622],
		[109.377082, 30.962344],
		[109.377834, 30.962478],
		[109.378509, 30.962344],
		[109.378704, 30.9621],
		[109.37899, 30.962033],
		[109.379288, 30.961866],
		[109.379288, 30.961755],
		[109.379106, 30.961577]
	]
]
export default regionalMaskPath;