import axios from 'axios'
// create an axios instance
const baseURL = 'http://116.63.187.6:8884/qg-center'
const service = axios.create({
	// withCredentials: true, // send cookies when cross-domain requests
	baseURL,
	timeout: 300000, // request timeout
})



// request interceptor
service.interceptors.request.use(
	(config) => {
			config.headers['Content-Type'] = 'application/json; charset=UTF-8'
		return config
	},
	(error) => {
		console.log(error)
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	(response) => {
		return response.data
	},
	(error) => {
		console.log(error)
		
		return Promise.reject(error)
	}
)
export default service