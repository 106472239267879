import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import request from './utils/request2.js'
Vue.prototype.$request = request

import requestFile from './utils/requestFile.js'
Vue.prototype.$requestFile = requestFile

import utils from './utils/utils.js'
Vue.prototype.utils = utils

import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer) 

import 'lib-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')